<template>
  <div>
    <q-card :dark="mode !== 'add'" v-if="mode" class="cat-card">
      <q-card-section class="h4 text-primary">
        <div class="row items-center justify-between">
          <div class="col-2">
            {{ message[lang].quiz_mode[mode] }} 
            <span v-show="quizItem?.ID && parent?.showSysInfo=='Y'">
              {{quizItem?.ID}}
            </span>
          </div>
          <div class="col-4" v-if="startFinish?.from">
            {{ parent.customFormatter(startFinish?.from) }} -
            {{ parent.customFormatter(startFinish?.to) }}
          </div>
          <div class="col-1 h5 text-secondary" v-if="anonim == 3">
            {{ message[lang].quiz_anon }}
          </div>
          <div class="col-2" v-if="is_likes && mode === 'view'">
            <q-rating v-model="rating" size="1em" color="warning" icon="favorite_border" :max="5" @update:model-value="sendRating" />
          </div>
          <div class="col-2" v-if="parent.isModer">
            <div class="text-secondaty" v-if="quizItem?.ACTIVE == 'Y'">{{ message[lang].quiz_published }}</div>
            <div class="text-warning" v-else>{{ message[lang].quiz_unpublished }}</div>
          </div>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-section class="h4 text-accent" v-if="mode === 'add' || mode === 'edit'">
        <div class="row">
          <div class="col-7">
            <q-input class="q-mb-md" outlined v-model="title" :label="message[lang].quiz_title + ' *'" />
            <div>{{ message[lang].quiz_description }}</div>
            <q-editor v-model="description" min-height="5rem" />
          </div>
          <div class="col-5 q-pl-md">
            <!-- Логотип-->
            <div class="row items-center">
              <div class="col-10" v-if="picture">
                <q-img :src="picture" spinner-size="2rem" spinner-color="positive" style="border: #dddddd 1px dashed; border-radius: 15px" />
                <q-btn size="xs" class="absolute all-pointer-events q-ma-xs" round icon="clear" color="positive" @click="picture = null">
                  <q-tooltip class="bg-primary h-tt">
                    {{ message[lang].remove }}
                  </q-tooltip>
                </q-btn>
              </div>
              <div class="col-12" v-else>
                <div class="col-4">
                  <q-uploader
                    :url="`${parent.app_server_url}api/sendimage?client_key=${parent.member_id}&portal=${parent.portal}&lesson_id=${lesson_id}&access_token=${parent.access_token}`"
                    style="max-width: 300px"
                    @uploaded="onUploaded"
                    :label="message[lang].picture"
                    flat
                    bordered
                    accept="image/jpeg, image/png, .svg"
                    @added="logo_added = true"
                    @removed="logo_added = false"
                  />
                </div>
                <div class="col-12 text-warning text-left q-pt-sm" v-if="logo_added">
                  <!-- <q-icon name="arrow_back" color="warning"/> -->
                  {{ message[lang].logo_added }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </q-card-section>
      <q-card-section v-else class="h4 text-accent q-py-xs">
        <div class="row">
          <div class="col-8 q-pa-sm">
            <div class="h3 text-primary">{{ title }}</div>
            <div class="h4 text-accent" v-html="description" />
          </div>
          <div class="col-4 q-pa-sm" v-if="picture">
            <q-img :src="picture" spinner-size="2rem" spinner-color="positive" style="border-radius: 15px" />
          </div>
        </div>
      </q-card-section>

      <q-card-section v-if="mode === 'add' || mode === 'edit'" class="h4 text-accent">
        <q-list separator class="q-my-sm">
          <draggable :list="list">
            <transition-group>
              <q-item v-for="(item, index) in list" :key="item.id">
                <q-item-section class="q-gutter-sm">
                  <q-input v-model="item.text" :label="message[lang].quiz_question + ' ' + (index + 1)">
                    <template v-slot:prepend>
                      <q-icon color="positive" name="menu" class="cursor-pointer" />
                    </template>
                    <template v-slot:append>
                      <q-icon color="positive" name="clear" @click.stop.prevent="delQuest(index)" class="cursor-pointer">
                        <q-tooltip class="bg-primary h-tt">{{ message[lang].quiz_quest_del }}</q-tooltip>
                      </q-icon>
                    </template>
                  </q-input>

                  <draggable v-model="item.a_list" group="answer" :sortable="false">
                    <transition-group>
                      <div v-for="(answer, aindex) in item.a_list" :key="answer.id" class="q-ml-lg q-my-sm h5 text-accent">
                        <q-input outlined v-model="answer.text" @update:model-value="addAnsw(index)" debounce="500" :label="message[lang].quiz_answer + ' ' + (aindex + 1)">
                          <template v-slot:prepend>
                            <q-icon color="positive" name="menu" class="cursor-pointer" />
                          </template>
                          <template v-slot:append>
                            <q-icon color="positive" name="clear" @click.stop.prevent="delAnsw(index, aindex)" class="cursor-pointer">
                              <q-tooltip class="bg-primary h-tt">{{ message[lang].quiz_answer_del }}</q-tooltip>
                            </q-icon>
                          </template>
                        </q-input>
                      </div>
                    </transition-group>
                  </draggable>
                  <div>
                    <q-btn v-if="isValidAddAnswer(index)" color="accent" flat no-caps rounded icon="add" @click="addAnsw(index)" :label="message[lang].quiz_answer_add" />
                  </div>
                  <q-separator />
                </q-item-section>
              </q-item>
            </transition-group>
          </draggable>
        </q-list>

        <q-btn v-if="isValidAddQuest" color="primary" flat no-caps rounded icon="add" @click="addQuest" :label="message[lang].quiz_quest_add" />
      </q-card-section>
      <!-- Голосование -->
      <q-card-section v-else-if="mode === 'view' && !isImVoted" class="h4 text-accent">
        <q-list>
          <q-item v-for="(item, index) in list" :key="index">
            <q-item-section class="q-gutter-sm">
              <div class="primary h4">{{ item.text }}</div>

              <div v-for="(answer, aindex) in item.a_list" :key="aindex" class="q-ml-lg h5 text-accent">
                <div v-if="is_multy">
                  <q-checkbox v-model="answer.checked" :label="answer.text" />
                </div>
                <div v-else>
                  <q-radio v-model="item.checked" :val="answer.id" :label="answer.text" />
                </div>
              </div>
            </q-item-section>
          </q-item>
        </q-list>
        <q-btn :disabled="!isValidSend" color="primary" unelevated no-caps rounded icon="send" @click="sendQuiz" :label="message[lang].quiz_send">
          <q-tooltip v-if="!isValidSend" class="bg-warning h-tt">{{ message[lang].quiz_send_novalid }}</q-tooltip>
          <q-tooltip v-else class="bg-primary h-tt">{{ message[lang].quiz_send_valid }}</q-tooltip>
        </q-btn>

        <span v-if="anonim == 1" class="q-ml-md">
          <q-checkbox v-model="hide_me" :label="message[lang].quiz_hide_me" />
        </span>
      </q-card-section>
      <!-- Просмотр результатов -->
      <q-card-section v-else-if="mode === 'view' && isImVoted" class="h4 text-accent q-py-xs">
        <div>
          {{ message[lang].quiz_res_len }}: {{ results.length }}
          <span v-if="anonim < 3 && results.length > 0">
            <q-icon name="visibility" class="cursor-pointer" @click="showUsersVotes" />
          </span>
        </div>
        <q-list>
          <q-item v-for="(item, index) in list" :key="index">
            <q-item-section class="q-gutter-xs">
              <div class="primary h4">{{ item.text }}</div>

              <div v-for="(answer, aindex) in item.a_list" :key="aindex" class="q-ml-lg h5 text-accent">
                <q-linear-progress rounded size="25px" :value="answerPercent(item, answer)" color="primary" track-color="accent" class="q-mt-sm">
                  <div class="absolute-full flex items-center justify-start text-negative h5 q-pl-sm">
                    {{ answer.text }}
                  </div>
                  <div class="absolute-full flex items-center justify-end text-negative h5 q-pr-sm">
                    {{ (answerPercent(item, answer) * 100).toFixed(1) + '%' }}
                    <!-- <q-badge color="negative" text-color="primary" :label="(answerPercent(item, answer)*100).toFixed(1)+'%'" /> -->
                  </div>
                </q-linear-progress>
              </div>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>

      <!-- Просмотр результатов комментарии -->
      <q-card-section v-if="mode === 'view' && is_comments" class="h4 text-accent q-py-xs">
        <div class="h5 q-px-lg">
          <!-- <q-scroll-area style="height: 500px;"> -->
          <Comments v-bind:lang="lang" v-bind:entity-id="quizItem.ID" v-bind:user_current="parent.user_current" v-bind:parent="parent" v-bind:mode="'list'" v-bind:heght="500" />
          <!-- </q-scroll-area> -->
        </div>
      </q-card-section>

      <q-card-section v-if="mode === 'add' || mode === 'edit'">
        <div class="row">
          <div class="col-7">
            <div class="column justify-start q-gutter-sm q-mt-xs text-accent">
              <q-select style="max-width: 360px" outlined map-options emit-value v-model="anonim" :options="message[lang].quiz_anon_options" :label="message[lang].quiz_anon_label" />

              <q-checkbox :disable="!!quizItem?.STUFF" v-model="is_multy" :label="message[lang].quiz_is_multy" />
              <q-checkbox v-model="is_comments" :label="message[lang].quiz_is_comments" />
              <q-checkbox v-model="is_likes" :label="message[lang].quiz_is_likes" />
              <q-checkbox v-model="allaccess" :label="message[lang].quiz_is_all_access" />
            </div>

            <div v-if="!allaccess" class="row justify-start q-gutter-xs q-mt-xs">
              <q-btn icon-right="add" flat no-caps rounded color="accent" :label="message[lang].quiz_users_add" @click="usersAdd" />
              <div v-for="(user, user_index) in users" :key="user.id">
                <q-chip color="primary" outline removable @remove="users.splice(user_index, 1)" size="sm">
                  {{ user.name }}
                </q-chip>
              </div>
            </div>

            <div v-if="!allaccess" class="row justify-start q-gutter-xs q-mt-xs text-accent">
              <q-btn icon-right="add" flat no-caps rounded color="accent" :label="message[lang].quiz_deps_add" @click="depsAdd" />
              <div v-for="(dep, dep_index) in departments" :key="dep.value">
                <q-chip color="secondary" outline removable @remove="dep.splice(dep_index, 1)" size="sm">
                  {{ dep.text }}
                </q-chip>
              </div>
            </div>
          </div>
          <div class="col-5 text-accent">
            <q-date v-model="startFinish" range minimal :title="message[lang].start_finish" :subtitle="showTitleDate()" :locale="lang === 'ru' ? parent.appLocale.ru : ''" mask="YYYY-MM-DD" />
          </div>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions v-if="mode === 'add' || mode === 'edit'">
        <q-btn flat rounded :label="message[lang].quiz_cancel" color="primary" @click="emitCancel" />
        <q-btn :disabled="!isValidSave" flat rounded :label="message[lang].quiz_save" color="primary" @click="emitSave" />
        <q-btn :disabled="!isValidPublish" v-if="quizItem?.ACTIVE === 'Y'" flat rounded :label="message[lang].quiz_unpublish" color="primary" @click="emitPublish(false)" />
        <q-btn :disabled="!isValidPublish" v-else flat rounded :label="message[lang].quiz_publish" color="primary" @click="emitPublish(true)" />
        <q-btn v-if="quizItem?.ID" flat rounded :label="message[lang].quiz_delete" color="primary" @click="emitDelete" />
        <q-btn v-if="quizItem?.STUFF" flat rounded :label="message[lang].quiz_results_del" color="primary" @click="emitResultsDel" />
      </q-card-actions>
      <q-card-actions v-else-if="mode === 'view' && (parent.isModer || parent.user_current.ID == quizItem?.PREVIEW_TEXT)">
        <!-- eslint-disable-next-line vue/no-mutating-props -->
        <q-btn flat rounded :label="message[lang].quiz_quiz_edit" color="primary" @click="mode = 'edit'" />
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import {ref, reactive, computed, onMounted, onBeforeMount} from 'vue';
import {VueDraggableNext} from 'vue-draggable-next';
import dayjs from 'dayjs';
import {messageObj} from 'src/helpers/message';
import Comments from 'src/Comments.vue';

export default {
  name: 'Quiz',
  props: {
    parent: Object,
    lang: String,
    quizItem: Object,
    modeItem: String, // add|edit|view
    index: Number,
  },
  components: {
    Comments,
    draggable: VueDraggableNext,
  },
  setup(props, {emit}) {
    const message = messageObj;
    let mode = ref('');
    let title = ref('');
    let description = ref('');
    let users = reactive([]);
    let departments = reactive([]);
    let results = reactive([]);
    let is_multy = ref(false);
    let is_comments = ref(true);
    let is_likes = ref(true);
    let allaccess = ref(true);
    let anonim = ref(1);
    let rating = ref(0);
    let startFinish = ref({from: dayjs().format('YYYY-MM-DD'), to: dayjs().add(1, 'month').format('YYYY-MM-DD')});
    let hide_me = ref(false);
    let picture = ref(null);
    let logo_added = ref(false);

    let list = reactive([]);

    const isImVoted = computed(() => {
      // const ret = results.some(item => item.uid == props.parent.user_current.ID);
      const result = results.filter((item) => item.uid == props.parent.user_current.ID);
      let ret = false;
      if (result?.length > 0 && result[0]?.res?.length > 0) {
        for (let item of result[0].res) {
          if (item.ch) {
            ret = true;
            break;
          }
          if (item?.answ?.length > 0) {
            let isBreak = false;
            for (let answ of item.answ) {
              if (answ.ch) {
                ret = true;
                isBreak = true;
                break;
              }
            }
            if (isBreak) {
              break;
            }
          }
        }
      }

      // console.log('::isImVoted results=',ret,results)
      return ret;
    });

    const isValidSave = computed(() => {
      return title.value.trim() > '';
    });

    const isValidPublish = computed(() => {
      return isValidSave.value && isValidList.value;
    });

    const isValidList = computed(() => {
      for (let item of list) {
        if (item.text) {
          let answLIst = [];
          for (let answ of item.a_list) {
            if (answ.text) {
              return true; //если есть хотя бы один вопрос с хотя бы одним ответом
            }
          }
        }
      }
      return false;
    });

    const isValidSend = computed(() => {
      let today = dayjs().format('YYYY-MM-DD');
      if (today < props.quizItem.DATE_ACTIVE_FROM || today > props.quizItem.DATE_ACTIVE_TO) {
        return false;
      }
      for (let item of list) {
        if (item.checked) {
          return true;
        }
        if (item.text) {
          let answLIst = [];
          for (let answ of item.a_list) {
            if (answ.checked) {
              return true; //если есть хотя бы один вопрос с хотя бы одним отмеченным  ответом
            }
          }
        }
      }
      return false;
    });

    const isValidAddQuest = computed(() => {
      return list.length == 0 || list[list.length - 1].text.trim() > '';
    });

    onBeforeMount(() => {
      mode.value = props.modeItem;
      // console.log('beforeMount mode=', mode.value);
    });

    onMounted(() => {
      if (props.quizItem) {
        title.value = props.parent.restoreQuotes(props.quizItem?.NAME);
        picture.value = props.quizItem?.DETAIL_PICTURE;
        description.value = props.parent.restoreQuotes(props.quizItem?.DETAIL_TEXT);
        let new_list = props.parent.isJSON(props.quizItem.PROPERTY_VALUES.list) ? JSON.parse(props.quizItem.PROPERTY_VALUES.list) : [];
        new_list = restoreList(new_list);
        list.push(...new_list);
        let new_users = props.parent.isJSON(props.quizItem.PROPERTY_VALUES.users) ? JSON.parse(props.quizItem.PROPERTY_VALUES.users) : [];
        users.push(...new_users);
        let new_departments = props.parent.isJSON(props.quizItem.PROPERTY_VALUES.deps) ? JSON.parse(props.quizItem.PROPERTY_VALUES.deps) : [];
        departments.push(...new_departments);
        let new_results = props.parent.isJSON(props.quizItem.STUFF) ? JSON.parse(props.quizItem.STUFF) : [];
        results.push(...new_results);

        const my_results = new_results.find((el) => el.uid == props.parent.user_current.ID);
        const my_rating = my_results?.rating || 0;
        rating.value = my_rating;

        allaccess.value = props.quizItem.PROPERTY_VALUES.allaccess === true || props.quizItem.PROPERTY_VALUES.allaccess === 'true' ? true : false;

        const flags = props.quizItem?.PROPERTY_VALUES?.flags ? JSON.parse(props.quizItem?.PROPERTY_VALUES?.flags) : {};

        is_multy.value = flags.is_multy ? true : false;
        is_comments.value = flags.is_comments ? true : false;
        is_likes.value = flags.is_likes ? true : false;
        anonim.value = flags.anonim || 1;

        startFinish.value = {from: '', to: ''};
        if (props.quizItem?.DATE_ACTIVE_FROM) {
          startFinish.value.from = props.quizItem?.DATE_ACTIVE_FROM;
        }
        if (props.quizItem?.DATE_ACTIVE_TO) {
          startFinish.value.to = props.quizItem?.DATE_ACTIVE_TO;
        }

        // console.log(':=======:onMounted title.value=', title.value, props.quizItem);
      }

      if (mode.value === 'add') {
        //  console.log(':: props.quizItem=',props.quizItem)
        //если это новый опрос сразу добавим один пустой вопрос с одним пустым ответом
        addQuest();
      } else if (mode.value === 'edit') {
        //  console.log(':: props.quizItem=',props.quizItem)
      } else if (mode.value === 'view') {
        // console.log('::quizItem=',props.quizItem)
      }
    });

    const onUploaded = (info) => {
      console.log(info);
      if (info.xhr && props.parent.isJSON(info.xhr.response)) {
        let res = JSON.parse(info.xhr.response);
        picture.value = res.location;
      }
      logo_added.value = false;
    };

    const showUsersVotes = () => {
      // console.log(':: results=', results);
      const uids = results?.length ? results.filter((el) => !el.hide_me).map((el) => el.uid) : null;
      // console.log(':: uids=', uids);
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.notify_title = '';
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.notify_text = '...';
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.notify_warning = false;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.notify_persistent = false;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.notify_no_btn = true;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.notify_position = 'left';
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.notify = true;
      if (uids?.length) {
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.notify_loading = true;
        props.parent.getUsers(null, null, uids).then((users) => {
          // console.log('::users=', users);
          let text = users.map((item) => item.name + ' ' + getRating(item.id)).join('<br>');
          // eslint-disable-next-line vue/no-mutating-props
          props.parent.notify_text = text;
          // eslint-disable-next-line vue/no-mutating-props
          props.parent.notify_loading = false;
        });

        let getRating = (user_id) => {
          let ret = 0;
          if (results?.length) {
            ret = results.find((el) => el.uid == user_id)?.rating || 0;
          }
          if (ret > 0) {
            const symbol = '❤️';
            let str = '';
            for (let i = 0; i < ret; i++) {
              str += symbol;
            }
            ret = str;
          }
          return ret || '';
        };
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.notify_text = message[props.lang].quiz_all_res_hided;
      }
    };

    const showTitleDate = () => {
      let ret = '- - -';
      if (startFinish.value) {
        if (startFinish.value.from && !startFinish.value.to) {
          ret = dayjs(startFinish.value.from).format('DD.MM.YYYY') + ' - ?';
        } else if (startFinish.value.from && startFinish.value.to) {
          ret = dayjs(startFinish.value.from).format('DD.MM.YYYY') + ' - ' + dayjs(startFinish.value.to).format('DD.MM.YYYY');
        }
      }
      // console.log('::showTitleDate ret=',ret)

      return ret;
    };

    const save_item = () => {
      // console.log('::save_item allaccess.value= ', is_multy.value, is_comments.value, is_likes.value, allaccess.value);

      const uids = users.map((item) => 'U' + item.id);
      const dids = departments.map((item) => 'D' + item.value);
      const results = getResults();

      const ret = {
        id: props?.quizItem?.ID,
        title: props.parent.replaceQuotes(title.value),
        description: props.parent.replaceTags(description.value),
        list: trimList(), //JSON.stringify(list),
        users: JSON.stringify(users),
        departments: JSON.stringify(departments),
        is_multy: is_multy.value,
        is_comments: is_comments.value,
        is_likes: is_likes.value,
        allaccess: allaccess.value,
        anonim: anonim.value,
        startFinish: startFinish.value,
        search: JSON.stringify({uids: uids, dids: dids}),
        index: props.index,
        results: results,
        picture: picture.value,
      };
      // console.log('::save_item = ', ret);
      return ret;
    };

    const usersAdd = () => {
      props.parent.usersAdd(users).then((userlist) => {
        // console.log(':: userlist=', userlist);
        users.splice(0, 0, ...userlist);
      });
    };

    const depsAdd = () => {
      props.parent.getDepartments().then((res) => {
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_list = [];
        for (let i in res) {
          let item = res[i];
          // eslint-disable-next-line vue/no-mutating-props
          props.parent.modal_list.push(item);
        }

        // eslint-disable-next-line vue/no-mutating-props
        props.parent.model = null;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_flt = '';
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_list_single = false;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_list_label = message[props.lang].selectdeps_label;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_title = message[props.lang].selectdep_title;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_message = message[props.lang].selectdep_message;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_ok = message[props.lang].selectusers_add;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_cancel = message[props.lang].selectusers_finish;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.modal_filterFn = null;
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.okModal = () => {
          let deps_id = props.parent.modal_list_selected;
          // console.log('::allDeps', deps_id);
          props.parent.getAllSubDeps(props.parent.modal_list, parseInt(props.parent.modal_list_selected), deps_id);
          let deps_full = props.parent.modal_list.filter((item) => deps_id.includes(item.value));

          departments.splice(0, departments.length, ...deps_full);
          // console.log('::departments', departments);

          // eslint-disable-next-line vue/no-mutating-props
          props.parent.hideModal();
        };
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.showModal();
      });
    };

    const delQuest = (index) => {
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.do_confirm = () => {
        list.splice(index, 1);
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.confirm = false;
      };
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.title_confirm = false;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.message_confirm = message[props.lang].quiz_quest_del + '?';
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.modal_ok = message[props.lang].quiz_quest_del;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.modal_cancel = false;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.confirm = true;
    };

    const delAnsw = (index, aindex) => {
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.do_confirm = () => {
        list[index].a_list.splice(aindex, 1);
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.confirm = false;
      };
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.title_confirm = false;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.message_confirm = message[props.lang].quiz_answer_del + '?';
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.modal_ok = message[props.lang].quiz_answer_del;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.modal_cancel = false;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.confirm = true;
    };

    const isValidAddAnswer = (index) => {
      return list[index].a_list.length == 0 || list[index].a_list[list[index].a_list.length - 1].text.trim() > '';
    };
    const addAnsw = (index) => {
      if (isValidAddAnswer(index)) {
        list[index].a_list.push({
          id: props.parent.getRandomNumber(100),
          text: '',
          checked: null,
        });
      }
    };

    const addQuest = () => {
      // console.log(':: addQuest');
      list.push({
        id: props.parent.getRandomNumber(100),
        text: '',
        checked: false,
        a_list: [
          {
            id: props.parent.getRandomNumber(100),
            text: '',
            checked: false,
          },
          {
            id: props.parent.getRandomNumber(100),
            text: '',
            checked: false,
          },
        ],
      });
    };

    /**
     * Результаты моего голосования с рейтингом и признаком анонимности
     * они будут вставлены в список всех результатов опроса
     */
    const getResults = () => {
      let res = [];
      const uid = props.parent.user_current.ID;
      if (isImVoted.value) {
        //Я уже отвечал на вопросы и это простановка рейтинга
        const index = results.findIndex((el) => {
          return el.uid == uid;
        });
        if (index > -1) {
          results[index].rating = rating.value;
          return results[index];
        } else {
          return {uid: uid, res: [], hide_me: hide_me.value, rating: rating.value};
        }
      } else {
        //Это ответы на вопросы и возможно рейтинга
        // console.log(':: getResults ', is_multy.value, list);
        for (let item of list) {
          //Если ответ не множественный, то в item.checked находится id выбранного ответа
          let resItem = {qid: item.id, ch: item.checked};
          if (is_multy.value) {
            resItem.answ = []; //для множественных ответов здесь массив id ответа -  true|false
            for (let answ of item.a_list) {
              resItem.answ.push({aid: answ.id, ch: answ.checked});
            }
          }
          res.push(resItem);
        }
        // console.log(':: getResults res=', res);
        return {uid: uid, res: res, hide_me: hide_me.value, rating: rating.value};
      }
    };

    const trimList = () => {
      let newList = [];
      for (let item of list) {
        let quest = {...item};
        quest.checked = false;

        if (item.text.trim()) {
          let answLIst = [];
          for (let answ of item.a_list) {
            if (answ.text.trim()) {
              let answer = {...answ};
              answer.checked = false;
              answer.text = props.parent.replaceQuotes(answer.text);
              answLIst.push(answer);
            }
          }
          quest.a_list = answLIst;
          quest.text = props.parent.replaceQuotes(quest.text);
          newList.push(quest);
        }
      }
      // console.log(':: trimList=', newList);
      return JSON.stringify(newList);
    };

    const restoreList = (list) => {
      let newList = [];
      for (let item of list) {
        let quest = {...item};
        if (item.text.trim()) {
          let answLIst = [];
          for (let answ of item.a_list) {
            if (answ.text.trim()) {
              let answer = {...answ};
              answer.text = props.parent.restoreQuotes(answer.text);
              answLIst.push(answer);
            }
          }
          quest.a_list = answLIst;
          quest.text = props.parent.restoreQuotes(quest.text);
          newList.push(quest);
        }
      }
      // console.log(':: restoreList=', newList);
      return newList;
    };

    const emitCancel = () => {
      mode.value = props.modeItem;
      emit('quiz_cancel');
    };

    const emitSave = () => {
      let saveItem = save_item();
      mode.value = props.modeItem;
      saveItem.publish = props.quizItem?.ID && props.quizItem?.ACTIVE ? props.quizItem.ACTIVE : 'N';
      // console.log('::emitSave saveItem=', saveItem);
      emit('quiz_save', saveItem);
    };

    const emitPublish = (isPublish) => {
      let saveItem = save_item();
      saveItem.publish = isPublish ? 'Y' : 'N';
      mode.value = props.modeItem;
      emit('quiz_save', saveItem);
    };

    const emitDelete = () => {
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.do_confirm = () => {
        mode.value = props.modeItem;
        emit('quiz_delete', save_item());
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.confirm = false;
      };
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.title_confirm = false;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.message_confirm = message[props.lang].quiz_delete + '?';
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.modal_ok = message[props.lang].quiz_delete;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.modal_cancel = false;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.confirm = true;
    };

    const sendQuiz = () => {
      // console.log(':: sendQuiz list=', list);
      emit('quiz_send', save_item());
    };

    const sendRating = () => {
      // console.log(':: sendQuiz list=', list);
      emit('quiz_rate', save_item());
    };

    const emitResultsDel = () => {
      // console.log(':: emitResultsDel stuff=', props.quizItem);
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.do_confirm = () => {
        mode.value = props.modeItem;
        emit('quiz_res_del', {id: props.quizItem.ID, index: props.index});
        // eslint-disable-next-line vue/no-mutating-props
        props.parent.confirm = false;
      };
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.title_confirm = false;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.message_confirm = message[props.lang].quiz_results_del + '?';
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.modal_ok = message[props.lang].quiz_results_del;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.modal_cancel = false;
      // eslint-disable-next-line vue/no-mutating-props
      props.parent.confirm = true;
    };

    const answerPercent = (quest, answer) => {
      // console.log('-', title.value);
      // console.log(':-1-: answerPercent is_multy,quest=',is_multy.value, quest.id, quest.text)
      // console.log(':-2-: answerPercent answer=', answer)
      // console.log(':-3-: answerPercent results=', results)
      let ret = 0;
      if (results?.length) {
        if (is_multy.value) {
          for (let one_user of results) {
            // console.log(':: one_user=',one_user)
            for (let item of one_user.res) {
              // console.log(':: item=',item)
              if (item.qid == quest.id) {
                if (item.answ) {
                  for (let answ of item.answ) {
                    //  console.log(':: answ=',answ)
                    if (answ.aid == answer.id && answ.ch) {
                      ret++;
                    }
                  }
                }
              }
            }
          }
        } else {
          for (let one_user of results) {
            let index = one_user.res.findIndex((el) => {
              return el.qid == quest.id && el.ch == answer.id;
            });
            if (index >= 0) {
              ret++;
            }
          }
        }
        return ret / results.length;
      }
      return 0;
    };

    return {
      mode,
      message,
      results,
      list,
      title,
      description,
      delQuest,
      delAnsw,
      addAnsw,
      addQuest,
      emitCancel,
      emitSave,
      emitPublish,
      emitDelete,
      usersAdd,
      users,
      departments,
      depsAdd,
      is_multy,
      is_comments,
      is_likes,
      allaccess,
      anonim,
      rating,
      isValidAddAnswer,
      isValidAddQuest,
      isValidPublish,
      isValidSave,
      isValidList,
      startFinish,
      showTitleDate,
      isValidSend,
      sendQuiz,
      answerPercent,
      isImVoted,
      emitResultsDel,
      showUsersVotes,
      hide_me,
      sendRating,
      logo_added,
      picture,
      onUploaded,
    };
  },
};
</script>
