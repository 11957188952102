<template>
  <div :class="!hideTabs ? 'component_padding q-my-md' : 'q-my-xs'">
    <div class="q-mr-md" v-if="!add_modal && !task_modal">

      <q-tabs v-model="tab" class="text-accent" active-color="primary" align="left">
        <q-tab v-if="!hideTabs" name="listDegree360" :label="message[lang].listDegree360"  />
        <q-tab v-if="!hideTabs" name="listUserDegree360" :label="message[lang].results" />
        <q-tab v-if="!hideTabs" name="Degree360StatusList" :label="message[lang].Degree360StatusList" />
        <!-- <q-tab v-if="!hideTabs" name="Degree360StatusFill" :label="message[lang].Degree360StatusFill" /> -->
      </q-tabs>

      <q-tab-panels v-model="tab" class="bg-dark text-accent">
        <!-- Оценки 360 список -->
        <q-tab-panel
          name="listDegree360"
          style="max-height: 750px; padding: 0px; margin: 0px" >
          <div v-if="!hideTabs" class="row items-center justify-between q-mb-md" style="margin-top: 0px" >

            <div class="col-3">
              <q-btn v-if="isChecked2(listDegree360)" icon="send" :loading="loading_send"
                  color="primary" flat no-caps rounded :label="message[lang].degree_status_btn" @click="sendMessages2(listDegree360)" >
                  <q-tooltip class="bg-primary h-tt">{{message[lang].degree_status_btn}}: {{message[lang].degree_fill_msg}}</q-tooltip>
              </q-btn>
            </div>
            <div class="col-3 text-right q-gutter-md" style="padding-right: 10px">
              <q-btn
                :disable="loading"
                padding="xs"
                size="lg"
                flat
                round
                color="secondary"
                icon="add_circle_outline"
                @click="addDegree360(null, null)"
              >
                <q-tooltip class="bg-primary h-tt">{{
                  message[lang].add_degree360
                }}</q-tooltip>
              </q-btn>
            </div>
          </div>

          <div
            class="g-mr-md q-pb-sm"
            v-if="!hideTabs || (listDegree360 && listDegree360.length)"
          >
            <div v-if="hideTabs" class="h3 text-accent q-my-sm">
              {{ message[lang].degree360_self_title }}
            </div>
            <q-card :class="hideTabs ? 'cat-card-small' : 'cat-card'">
              <q-list separator v-if="listDegree360 && listDegree360.length">
                <q-item
                  v-for="(item, index) in listDegree360"
                  :key="index"
                  clickable
                  v-ripple
                >
                  <q-item-section avatar>
                    <span v-if="isDegree360Agreed(item.DETAIL_TEXT)"> </span>
                    <q-checkbox  v-else-if="item.PREVIEW_TEXT"  v-model="item.check" />
                  </q-item-section>
                    
                  <q-item-section @click="addDegree360(index, item)">
                    <span :class="item.ACTIVE === 'Y' ? 'text-primary' : 'text-accent'">
                      
                      <span v-if="parent.showSysInfo=='Y'">{{item.ID}} </span>
                      {{ parent.restoreQuotes(item.NAME) }}

                      <span v-if="isDegree360Agreed(item.DETAIL_TEXT)" class="text-secondary" >
                        ({{ message[lang].degree360_agreed }})
                      </span>
                      <span v-else-if="item.PREVIEW_TEXT" class="text-warning">
                        ({{ message[lang].degree360_agreeID }})
                      </span>
                    </span>
                  </q-item-section>
                  <q-item-section side>
                    <q-btn
                      :disable="item.ACTIVE === 'Y'"
                      flat
                      round
                      icon="clear"
                      color="positive"
                      @click="delDegree360(index, item)"
                    />
                  </q-item-section>
                </q-item>
              </q-list>
              <div class="text-center" v-if="isMore">
                <q-btn
                  @click="loadDegrees360()"
                  round
                  flat
                  color="positive"
                  icon="keyboard_arrow_down"
                  :loading="loading"
                />
              </div>
            </q-card>
          </div>
        </q-tab-panel>
        <!-- Назначения список -->
        <q-tab-panel
          name="listUserDegree360"
          style="height: 750px; padding: 0px; margin: 0px" >
          <div class="row items-center justify-end q-mb-md" style="margin-top: 0px">
            <div class="col-3 text-right q-gutter-md" style="padding-right: 10px">
              <div class="q-pa-xs q-ma-lg"></div>
            </div>
          </div>

          <div class="row items-center justify-end q-mb-md" style="margin-top: 0px">
            <q-card class="cat-card">
              <q-list separator v-if="listDegree360 && listDegree360.length">
                <q-expansion-item
                  v-for="(item, index) in listDegree360"
                  :key="index"
                  :label="parent.restoreQuotes(item.NAME)"
                  expand-icon
                  @click="loadUserDegrees360(index, item)"
                  :ref="`exp_${item.ID}`"
                  group="somegroup"
                >
                  <q-card>
                    <!--  Статус заполнения -->
                    <q-card-section>
                      <div class="h3 text-primary q-my-sm">
                        {{ message[lang].Degree360StatusFill }}
                        <q-btn v-if="isChecked(item.listUserDegree360)" icon="send" :loading="loading_send"
                            color="primary" flat no-caps rounded :label="message[lang].degree_status_btn" @click="sendMessages(item.listUserDegree360)" >
                            <q-tooltip class="bg-primary h-tt">{{message[lang].degree_status_btn}}: {{message[lang].degree_fill_msg}}</q-tooltip>
                        </q-btn>
                        <q-btn v-if="isChecked(item.listUserDegree360)" icon="clear" :loading="loading_clear"
                            color="primary" flat no-caps rounded :label="message[lang].degree_clear_btn" @click="clearResults(item.listUserDegree360)" >
                            <q-tooltip class="bg-primary h-tt">{{message[lang].degree_clear_msg}}</q-tooltip>
                        </q-btn>
                      </div>
                      <div v-if="item.listUserDegree360">
                        <q-card  flat bordered class="bg-info text-accent">
                          <q-card-section>
                            <q-list dense separator>
                              <q-item dense class=" bg-primary text-negative">
                                <q-item-section >
                                  <div class="row items-center q-py-sm">
                                    <div class="col-2">{{ message[lang].persona }}</div>
                                    <div class="col-5">{{ message[lang].experts }}</div>
                                    <div class="col-4">{{ message[lang].Degree360StatusFill }}</div>
                                  </div>
                                </q-item-section>
                              </q-item>
                              
                              <q-item  dense v-for="(item, statind) in item.listUserDegree360" :key="statind">
                                  <q-item-section v-if="item?.statuses?.student?.name">
                                    <div class="row items-center">
                                      <div class="col-12 text-accent">{{ item.statuses.student.name }}
                                        <span v-if="item.statuses.student.status>0" class="text-warning q-mx-sm" >
                                          {{ message[lang].degree_status_student[item.statuses.student.status] }}
                                        </span>
                                      </div>
                                      <div class="col-10 offset-1">
                                        <div class="row items-center" v-for="exp in item.statuses.experts" :key="exp.id">
                                          <div class="col-1"><q-checkbox v-model="exp.check" /></div>
                                          <div class="col-6">{{ exp.name }}</div>
                                          <div :class="exp.status>0?'text-warning col-5':'text-secondary col-5'">
                                            {{ message[lang].degree_status_expert[exp.status] }}
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                  </q-item-section>
                              </q-item>
                            </q-list>
                          </q-card-section>
                        </q-card>
                      </div>
                    </q-card-section>

                    <!--  Шкала оценки -->
                    <q-card-section>
                      <div class="h3 text-primary q-my-sm">
                        {{ message[lang].selectscale }}
                      </div>
                      <div v-if="item.DETAIL_TEXT.scale && item.DETAIL_TEXT.scale.length">
                        <q-card flat bordered class="bg-info text-accent">
                          <q-card-section>
                            <q-list dense separator>
                              <q-item
                                dense
                                v-for="scale in item.DETAIL_TEXT.scale"
                                :key="scale.id"
                              >
                                <q-item-section>
                                  <div class="row items-center">
                                    <div class="col-2">{{ scale.value }}</div>
                                    <div class="col-5">{{ scale.name }}</div>
                                    <div class="col-5"><div v-html="scale.desc" /></div>
                                  </div>
                                </q-item-section>
                              </q-item>
                            </q-list>
                          </q-card-section>
                        </q-card>
                      </div>
                    </q-card-section>

                    <q-card-section v-if="item.listUserDegree360">
                      <!-- Количество респондентов, прнимавших участие в оценке -->
                      <q-table v-if="item.statTable"
                        flat
                        bordered
                        :title="message[lang].degree360_stat_title"
                        :rows="item.statTable.rows"
                        :columns="item.statTable.cols"
                        row-key="name"
                        hide-bottom
                      >
                        <template v-slot:header="props">
                          <q-tr :props="props">
                            <q-th
                              v-for="col in props.cols"
                              :key="col.name"
                              :props="props"
                              class="bg-primary text-info h4"
                              style="max-width: 200px"
                              v-html="col.label"
                            />
                          </q-tr>
                        </template>
                      </q-table>
                    </q-card-section>

                    <q-card-section v-if="item.averTable">
                      <!-- Результаты оценок (средние значения) -->
                      <div class="h3 text-primary q-my-sm">
                        {{ message[lang].degree360_aver_title }}
                      </div>
                      <div v-for="(rowItem, indItem) in item.averTable" :key="indItem">
                        <q-table
                          v-if="rowItem.comps && rowItem.comps.length"
                          flat
                          bordered
                          :title="rowItem.stud_name"
                          :rows="rowItem.comps"
                          :columns="parent.columnsAver()"
                          row-key="name"
                          v-model:pagination="pagination"
                          :rows-per-page-label="message[lang].rows_per_page"
                        >
                          <template v-slot:header="props">
                            <q-tr :props="props">
                              <q-th
                                v-for="(col, index) in props.cols"
                                :key="col.name"
                                :props="props"
                                class="bg-primary text-info h4"
                                :style="
                                  index == 0 ? 'max-width: 500px' : 'max-width: 100px'
                                "
                                v-html="col.label"
                              />
                            </q-tr>
                          </template>
                        </q-table>
                        <!-- График -->
                        <div v-if="rowItem.charts" class="text-center q-my-md text-accent"> 
                          <div v-for="(chart, chind) in rowItem.charts" :key="chind" class="text-center q-my-md text-accent">
                            <apexcharts
                              height="350"
                              type="bar"
                              :options="chart.options"
                              :series="chart.series"
                            />
                          </div>
                        </div>  
                      </div>
                    </q-card-section>

                    <q-card-section v-if="item.listUserDegree360">
                      <!--  Комментарии -->
                      <div
                        v-for="udegreeItem in item.listUserDegree360"
                        :key="udegreeItem.ID"
                      >
                        <Comments
                          v-bind:lang="lang"
                          v-bind:entity-id="`udegree360_${udegreeItem.ID}`"
                          v-bind:user_current="parent.user_current"
                          v-bind:parent="parent"
                          v-bind:mode="'list'"
                          v-bind:hide_fio="
                            udegreeItem.flags && udegreeItem.flags.hide_fio === 'Y'
                          "
                        />
                      </div>
                    </q-card-section>
                  </q-card>

                  
                </q-expansion-item>
              </q-list>
              <div class="text-center" v-if="isMore">
                <q-btn
                  @click="loadDegrees360()"
                  round
                  flat
                  color="positive"
                  icon="keyboard_arrow_down"
                  :loading="loading"
                />
              </div>
            </q-card>
          </div>

        </q-tab-panel>
        <!-- Статусы оценок по самостоятельному выбору согласующих -->
        <q-tab-panel
          name="Degree360StatusList"
          style="height: 750px; padding: 0px; margin: 0px" >
          
          <degree360-status-list
            :parent="parent"
            :lang="lang"/>

        </q-tab-panel>
        
      </q-tab-panels>
      <q-inner-loading :showing="loading_send">
            <q-spinner-comment size="5em" color="primary" />
        </q-inner-loading>
      <q-inner-loading :showing="loading">
          <q-spinner-oval size="5em" color="positive" />
        </q-inner-loading>
    </div>


    <!-- <q-dialog v-model="add_modal" persistent full-width full-height> -->
    <!-- <q-card v-if="add_modal" class="bg-dark"> -->
    <!-- <q-card-section class="q-pt-xl"> -->
    <degree360-add
      v-if="add_modal"
      :degree360Item="degree360Item"
      :degree360Index="degree360Index"
      :parent="parent"
      :lang="lang"
      :back="back"
      :lkMode="hideTabs"
      @close="add_modal = false"
      @competence_saved="competence_saved"
      @competence_close="competence_close"
      @lk_load_list_degree360="lkLoadListDegree360"
    />
    <degree360-task-add
      v-if="task_modal"
      :degree360Item="degree360Item"
      :degree360Index="degree360Index"
      :parent="parent"
      :lang="lang"
      :back="back"
      :lkMode="hideTabs"
      @close="task_modal = false"
      @task_saved="task_saved"
      @task_close="task_close"
    />
    <!-- </q-card-section> -->
    <!-- </q-card> -->
    <!-- </q-dialog> -->
  </div>
</template>
<script>
import { messageObj } from "src/helpers/message";
import dayjs from "dayjs";
import VueApexCharts from "vue3-apexcharts"; //https://apexcharts.com/docs/
import degree360Add from "./Degree360Add.vue";
// import degree360TaskAdd from "./Degree360TaskAdd.vue";
import Comments from "./Comments.vue";
import Degree360StatusList from "./Degree360StatusList.vue";
// import Degree360StatusFill from "./Degree360StatusFill.vue";

export default {
  name: "degree360list",
  props: {
    parent: Object,
    lang: String,
    back: String,
    mntTab: String,
    hideTabs: Boolean,
  },
  data() {
    return {
      maxCount:3,
      expanded:false,
      loading_send:false,
      loading_clear:false,
      fab1: false,
      tab: "listDegree360",
      degree360Item: null,
      degree360Index: null,
      add_modal: false,
      task_modal: false,
      loading: false,
      listDegree360: [],
      listTaskDegree360: [],
      total: 0,
      count: 20,
      task_last_id: null,
      last_id: null,
      pagination: {
        sortBy: "desc",
        descending: false,
        page: 1,
        rowsPerPage: 20,
      },
      message: messageObj,
    };
  },
  computed: {
    isMore() {
      return (
        this.listDegree360 &&
        this.total &&
        !this.loading &&
        Object.keys(this.listDegree360).length >= this.count
      );
    },
  },
  watch: {},
  mounted() {
    this.loading = true;
    // const uid = this.parent.user_current.ID;
    this.maxCount=this.parent.getMaxCountByTarif('courses');
    // LK mode
    if (this.hideTabs) {
      this.lkLoadListDegree360();
    } else {
      this.loadDegrees360(true);
    }
  },
  methods: {
    sendMessages(list){
        this.loading_send = true;
        let checked = this.getChecked(list);
        for(let item of checked){
            this.parent.notifyLK(item.id, this.message[this.lang].degree_fill_msg, this.parent.user_current);
            item.check = false;
        }
        setTimeout(() => {
            this.loading_send = false;                
        }, 3000);

    },
    sendMessages2(list){
      this.loading_send = true;
      let checked = this.getChecked2(list);
      // console.log(':: sendMessages2 checked=', checked);
      for(let item of checked){
        let degree360 = JSON.parse(item.DETAIL_TEXT);
        console.log(':: sendMessages2 degree360=', degree360);
        if(degree360 && degree360.students){
          let message = this.message[this.lang].the_360+': '+degree360.name+' ';
          let uid = '';
          for(let stud of degree360.students){
            console.log(':: sendMessages2 stud=', stud);
            console.log(':: sendMessages2 agreeID[stud.id]=', degree360.agreeID[stud.id]);

            if(!stud.experts){
              //Оцениваемый все еще не назначил экспертов
              uid = stud.id;
              message = this.message[this.lang].degree_status_msg;
            }else if(degree360.agreeID && !degree360.agreeID[stud.id]){
              //Еще не назначен согласующий
              uid = stud.id;
              message = this.message[this.lang].degree_status_msg;
            }else if(!degree360.agreeID[stud.id].agreed){
              //не согласовано
              uid = degree360.agreeID[stud.id].agree_id
              message = this.message[this.lang].degree_uagree_msg;              
            }
            console.log(uid, message);
            this.parent.notifyLK(uid, message, this.parent.user_current);
          }
          item.check = false;
        }
        
      }
      setTimeout(() => {
          this.loading_send = false;                
      }, 3000);
    },
    clearResults(list){
      this.loading_clear = true;
      let checked = this.getChecked(list);
      for(let i in checked){
        let item = checked[i];
        let isLoadingClear = i>=checked.length-1;
        let udegree = list.find(el=>{return el.ID == item.udeg_id});
        // console.log(':: clearResults udegree=', udegree);
        if(udegree){
          let flt_results = udegree.results.filter(el=>{return el.user_id != item.id});
          udegree.results = flt_results;
          setTimeout(() => {
            item.check = false;
            this.udegreeUpdate(udegree, isLoadingClear);                      
          }, 500);
        }else{
          this.loading_clear = false;
        }

      }
    },
    udegreeUpdate(udegree, isLoadingClear){
      let $this = this;
      let method = "entityItemUpdate";
      let params = {
        ENTITY: "uni_udegree360",
        ID: udegree.ID,
      };
      var url = $this.parent.bx24Init({ AJAX_ACTION: method, params: params });
      $this.parent.bx24params.DETAIL_TEXT = JSON.stringify(udegree);
      $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
        if(isLoadingClear){
          $this.loading_clear = false;
          $this.$refs[`exp_${udegree.id}`][0].toggle();
        }
      });
    },
    getChecked(list){
      let ret = list ? list.flatMap(item =>  item?.statuses?.experts && item.statuses.experts.filter(expert => expert.check)):[];
      return ret;
    },
    getChecked2(list){
      // console.log(':: getChecked2 list=', list);
      let ret = list ? list.filter(el => el.check):[];
      return ret;
    },
    isChecked(list){
        return this.getChecked(list).length > 0;
    },
    isChecked2(list){
        return this.getChecked2(list).length > 0;
    },
    loadDegrees360(isReload) {
      this.loading = true;
      if (isReload) {
        this.last_id = null;
      }
      this.parent.loadPrograms("degree360", this.count, this.last_id).then((res) => {
        this.total = false;
        if (isReload) {
          this.listDegree360 = [];
        }
        this.listDegree360 = this.listDegree360 ? this.listDegree360 : [];
        // console.log(":: loadDegrees360 res=", res);
        if (res && res.length > 0) {
          this.total = true;
          for (let i in res) {
            const isQuota = this.maxCount > Object.keys(this.listDegree360).length;
            if(isQuota){
              let detail_text = this.parent.isJSON(res[i].DETAIL_TEXT) ? JSON.parse(res[i].DETAIL_TEXT) : res[i].DETAIL_TEXT;
              res[i].agreed = detail_text.agreed;
              res[i].agreeID = detail_text.agreeID;
              res[i].check = false;
              this.last_id = res[i].ID;
              this.listDegree360.push(res[i]);
            }else{
              console.error(':: Quota is riched...')
              break;
            }
          }
          // console.log(":: loadDegrees360 listDegree360=", this.listDegree360);
        }
        this.loading = false;
      });
    },
    lkLoadListDegree360() {
      const uid = this.parent.user_current.ID;
      //Мои согласования
      let filter = {
        1: {
          LOGIC: "AND",
          "%PREVIEW_TEXT": `%"agree_id":"${uid}"%`,
        },
      };
      // console.log(":LK:degree360 Мои согласования filter=", filter);
      this.parent.loadPrograms("degree360",this.count,this.last_id,null,null,null,null,filter).then((res) => {
          // console.log(":LK:degree360 Мои согласования  res=", res);
          for (let i in res) {
            let detail_text = this.parent.isJSON(res[i].DETAIL_TEXT)
              ? JSON.parse(res[i].DETAIL_TEXT)
              : res[i].DETAIL_TEXT;
            res[i].agreed = detail_text.agreed;
            res[i].agreeID = detail_text.agreeID;
          }
          this.listDegree360 = res;

          //Мои заявки на оценку
          let filter = {
            1: {
              LOGIC: "AND",
              "%STUFF": `%"${uid}"%`,
            },
          };
          // console.log(":LK:degree360 Мои заявки на оценку  filter=", filter);

          this.parent.loadPrograms("degree360",this.count,this.last_id,null,null,null,null,filter).then((res) => {
              // console.log(":LK:degree360 Мои заявки на оценку  res=", res);
              let my_tasks = [];
              for (let i in res) {
                let detail_text = this.parent.isJSON(res[i].DETAIL_TEXT)
                  ? JSON.parse(res[i].DETAIL_TEXT)
                  : res[i].DETAIL_TEXT;
                let passed = detail_text?.agreeID?.[uid]; //я уже подал заявку и указал согласующего
                // console.log(":: passed=", passed, detail_text);
                if (!detail_text?.flags?.is_self || !passed) {
                  continue;
                }
                res[i].agreed = this.isDegree360Agreed(detail_text);
                res[i].agreeID = detail_text.agreeID;
                my_tasks.push(res[i]);
              }
              // this.listDegree360 = [...my_tasks, ...this.listDegree360];
              // Объединение массивов
              const combinedArray = my_tasks.concat(this.listDegree360);

              // Удаление дубликатов
              const uniqueArray = combinedArray.filter(
                (item, index, self) => index == self.findIndex((t) => t.id == item.id)
              );
              this.listDegree360 = uniqueArray;
              this.loading = false;
              this.$emit("degree360_recalc");
            });
        });
    },
    isDegree360Agreed(item) {
      let degree360 = {};
      if(this.parent.isJSON(item)){
          degree360 = JSON.parse(item);
      }else{
        degree360 = item;
      }
      let ret = false;
      if (degree360.students && degree360.students.length > 0) {
        ret = true;
        for (let student of degree360.students) {
          if (!degree360?.agreeID?.[student.id]?.agreed) {
            ret = false;
            break;
          }
        }
      }
      return ret;
    },
    loadUserDegrees360(index, item) {
      let $this = this;
      console.log(":: loadUserDegrees360 ", index, item);
      
      item.DETAIL_TEXT = this.parent.isJSON(item.DETAIL_TEXT) ? JSON.parse(item.DETAIL_TEXT) : {};
      this.loading = true;
      let filter = {
        1: {
          LOGIC: "AND",
          NAME: parseInt(item.ID),
        },
      };

      this.parent.loadPrograms("udegree360",this.count,this.last_id,null,null,null,null,filter).then((res) => {
          console.log("::loadPrograms udegree360 res=", res);
          item.listUserDegree360 = [];
          for (let i in res) {
            let itemRow = this.parent.isJSON(res[i].DETAIL_TEXT) ? JSON.parse(res[i].DETAIL_TEXT) : {};
            item.listUserDegree360.push(itemRow);
          }
          this.setStudentStatus(item.listUserDegree360);
          $this.parent.retStat = null;
          item.statTable={
            rows: this.parent.rowsStat(item.listUserDegree360),
            cols: this.parent.columnsStat()
          }
          this.parent.averageTable(item);


          console.log( "::loadUserDegrees360 item.listUserDegree360=", item.listUserDegree360 );

          this.loading = false;
        });
    },
    setStudentStatus(studentList){
      for(let item of studentList){
        let statuses={student:{}, experts:[]};
        if(item?.student?.id){
          statuses={
            student:{id:item.student.id,name:item.student.name, status:0},
            experts:[]
          };
        }else{
          continue;
        }
        if(item.student.experts){
          for(let expert of item.student.experts){
            let exp = {
              id:expert.id,
              name:expert.name,
              status:0,
              udeg_id:item.ID,
              check:false
            };
            let status = 0; // все ответы заполнены
            //для каждого эксперта смотрим на его ответы в разрезе компетенций
            for(let comp of item.comps){
              if(item.results){
                //ответы этого эксперта для этой компетенции 
                const res_len = item.results.filter((el) => el.comp_id == comp.id && el.user_id == expert.id).length; 
                if(!res_len){
                  //ответов нет по крайней мере по одной компетенции
                  status = 2;
                  break;
                }else{
                  const ind_len = comp.indicators.length;
                  if(ind_len > res_len){
                    //ответы есть но не все
                    status = 3;
                    break;
                  }
                }
              }else{
                //нет результатов ни по одному из экспертов
                status=1;
                break;
              }
            }
            if(status==1){
              //нет результатов ни по одному из экспертов
              statuses.student.status = 1;
              break;
            }else{
              exp.status = status;
            }
            statuses.experts.push(exp);
          }
        } else{
          statuses.student.status = 2; //нет экспертов
        }
        //если эксперты есть и какие-то результаты тоже, вычислим статус студента на основании статусов экспертов
        // if(statuses.student.status==0){
        //   let stud_status = 0;
        //   for(let i in statuses.experts){
        //     stud_status += statuses.experts[i].status?10:0;
        //   }
        //   statuses.student.status = stud_status>0?1:2;
        // }
        //заполненные статусы возвращаем в лист студентов
        item.statuses=statuses;
      }
    },
    delDegree360(index, item) {
      console.log("::delProgram", index, item);
      let $this = this;
      const item_id = item.ID;
      $this.parent.do_confirm = () => {
        let params = {
          ENTITY: `uni_degree360`,
          ID: item_id,
        };
        let url = $this.parent.bx24Init({ AJAX_ACTION: "entityItemDel", params: params });
        $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
          // $this.checkDegree360Task(item_id);
          $this.listDegree360.splice(index, 1);
        });

        $this.parent.confirm = false;
      };
      $this.parent.title_confirm = this.message[this.lang].del_degree360;
      $this.parent.message_confirm =
        this.message[this.lang].del_degree360_msg + ' "' + this.parent.restoreQuotes(item.NAME) + '"?';
      $this.parent.modal_ok = null;
      $this.parent.modal_cancel = null;

      $this.parent.confirm = true;
    },
    // checkDegree360Task(degree360_id) {
    //   let $this = this;
    //   //Мои Задания
    //   const filter = {
    //     1: {
    //       LOGIC: "AND",
    //       "%PREVIEW_TEXT": `%"${this.parent.user_current.ID}"%`,
    //     },
    //   };

    //   this.parent
    //     .loadPrograms("tdegree360", null, null, null, null, null, null, filter)
    //     .then((res) => {
    //       console.log("::checkDegree360Task tdegree360 res=", res);
    //       for (let i in res) {
    //         let tdegree360 = this.parent.isJSON(res[i].DETAIL_TEXT)
    //           ? JSON.parse(res[i].DETAIL_TEXT)
    //           : res[i].DETAIL_TEXT;
    //         //Нашли задание по которому была создана эта Оценка
    //         if (tdegree360.degree360_id == degree360_id) {
    //           console.log("::checkDegree360Task tdegree360=", tdegree360);
    //           let Im =
    //             tdegree360.students &&
    //             tdegree360.students.find((el) => {
    //               return el.id == this.parent.user_current.ID;
    //             });
    //           if (Im) {
    //             Im.passed = false;
    //             this.$emit("self_task_update", tdegree360);
    //           }
    //         }
    //       }
    //       console.log("::loadPrograms listTaskDegree360=", this.listTaskDegree360);
    //     });
    // },
    addDegree360(index, item) {
      let $this = this;
      console.log(":: addDegree360 hideTabs=", this.hideTabs, index, item);
      // if(this.hideTabs && item?.agreeID?.[this.parent.user_current.ID]){
      //   //Это клик по оценке в ЛК и это Оцениваемый (не согласующий)
      //   //Если уже согласовано, то редактировать не даем
      //   if(item.agreed){
      //     $this.parent.notify_title = this.message[this.lang].attention;
      //     $this.parent.notify_text = this.message[this.lang].degree360_alert;
      //     $this.parent.notify_warning = true;
      //     $this.parent.notify = true;
      //   }else{
      //     this.$emit('self_edit', {item:item, index:index});
      //   }
      // }else{
      this.degree360Item = item;
      this.degree360Index = index;
      this.add_modal = true;
      // }
    },
    addTaskDegree360(index, item) {
      this.degree360Item = item;
      this.degree360Index = index;
      this.task_modal = true;
    },
    competence_saved(arg) {
      console.log(":1:competences_saved degree360=", arg);
      this.add_modal = false;
      this.loading = true;
      // let degree360_OLD = { ...arg.degree360 };
      //сохранение оценки360
      this.saveDegree360(arg.degree360).then((id) => {
        arg.degree360.id = id;
        this.$emit("degree360_recalc");
        if (!isNaN(parseInt(arg.index))) {
          //При редактировании запишем в массив изменения без перезагрузки списка
          this.listDegree360[arg.index].NAME = this.parent.restoreQuotes( arg.degree360.name );
          this.listDegree360[arg.index].DETAIL_TEXT = JSON.stringify(arg.degree360);
          this.listDegree360[arg.index].PREVIEW_TEXT = arg.degree360 ? arg.degree360.agreeID : "";
          this.listDegree360[arg.index].ACTIVE = arg.degree360.approved ? arg.degree360.approved : "N"; //N|Y
          this.listDegree360[arg.index].agreeID = arg.degree360.agreeID;
          this.listDegree360[arg.index].agreed = arg.degree360.agreed;
          this.listDegree360[arg.index].PROPERTY_VALUES.flags = arg.degree360.flags;
          this.loading = false;
        } else {
          this.loadDegrees360(true);
        }
        console.log(":2:competences_saved arg.doSchedules=", arg);
        if (arg.doSchedules) {
          //делаем назначения
          this.doSchedules(arg.degree360, arg.index, arg.degree360_OLD).then((res) => {
            console.log("::doSchedules res=", res);
            //После сохранения делаем уведомления
            // Уведомления Только для новых появившихся экспертов
            const allExpertIds_old = arg.degree360_OLD.students.flatMap((student) =>
              student.experts.map((expert) => expert.id)
            ) || [];
            console.log("::allExpertIds_old=", arg.allExpertIds_old);
            const allExpertIds_new = arg.degree360.students.flatMap((student) =>
              student.experts.map((expert) => expert.id)
            );
            console.log("::allExpertIds_new=", allExpertIds_new);
            const experts_ids = allExpertIds_new.filter(
              (element) => !allExpertIds_old.includes(element)
            );
            console.log("::experts_ids=", experts_ids);

            // this.parent.isNoteSet(experts_ids, "e360").then((checks) => {
            //   console.log("::Уведомления для Оценивающих", checks);
            //   if (Object.keys(checks).length > 0) {
            //     let arBatch2 = {};
            //     for (let uid of checks) {
            //       // let message = this.message[this.lang].you_agree_360;
            //       let message =
            //         this.message[this.lang].degree360_message +
            //         `<br><a target="_blank" href=${this.parent.appURL}?degree360=${arg.degree360.id}>${arg.degree360.name}</a>`;
            //       arBatch2["note_" + uid] = [
            //         "im.notify.system.add",
            //         {
            //           USER_ID: uid,
            //           MESSAGE: message,
            //           MESSAGE_OUT: message,
            //         },
            //       ];
            //     }

            //     this.parent.callBatch(arBatch2, (res) => {
            //       console.log("::Уведомления для Оценивающих отправлены res=", res);
            //     });
            //   }
            // });
          });
        }else if(arg.degree360?.flags?.is_self){
          //Уведомления для Оцениваемых, о том что им нужно назначить Оценивающих (экспертов)
          let stud_ids=[];
          for(let student of arg.degree360.students){
            if(!arg.degree360?.agreeID?.[student.id]){
              //Оцениваемый еще не присупал к формированию списка своих оценивающих
               stud_ids.push(student.id);   
            }
          }
          // if(stud_ids.length>0){
          //   this.parent.isNoteSet(stud_ids, "e360").then((checks) => {
          //     console.log("::Уведомления для Оцениваемых ", checks);
          //     if (Object.keys(checks).length > 0) {
          //       let arBatch2 = {};
          //       for (let uid of checks) {
          //         // let message = this.message[this.lang].you_agree_360;
          //         let message =
          //           this.message[this.lang].degree360_self_message +
          //           `<br><a target="_blank" href=${this.parent.appURL}?degree360=${arg.degree360.id}>${arg.degree360.name}</a>`;
          //         arBatch2["note_" + uid] = [
          //           "im.notify.system.add",
          //           {
          //             USER_ID: uid,
          //             MESSAGE: message,
          //             MESSAGE_OUT: message,
          //           },
          //         ];
          //       }

          //       this.parent.callBatch(arBatch2, (res) => {
          //         console.log("::Уведомления для Оцениваемых отправлены res=", res);
          //       });
          //     }
          //   });
          // }

        }
      });
    },
    doSchedules(degree360, degree360Index, degree360_OLD) {
      let $this = this;
      return new Promise(function (resolve, reject) {
        let uDegree360 = { ...degree360 };
        delete uDegree360.students;
        let arBatch = {};
        for (let student of degree360.students) {
          let sched_id = student.sched; // id сделанного назначения оценки 360
          if (student.sched || !student.experts) {
            continue; //Если уже есть назначение - пропускаем
          }
          uDegree360.student = student;
          let stud_id = student.id; // оцениваемый студент
          let method = "entityItemAdd";
          let params = {
            ENTITY: "uni_udegree360",
            PREVIEW_TEXT: parseInt(stud_id), //студент
            NAME: degree360.id, //id оценки 360
            ACTIVE: "Y",
            DATE_ACTIVE_FROM: dayjs(degree360.date_start).format("YYYY-MM-DD"),
            DATE_ACTIVE_TO: dayjs(degree360.date_finish).format("YYYY-MM-DD"),
            DETAIL_TEXT: JSON.stringify(uDegree360),
            STUFF: JSON.stringify(
              student.experts.map((el) => {
                return el.id;
              })
            ),
          };
          // if (sched_id) {
          //   method = "entityItemUpdate";
          //   params["ID"] = sched_id;
          // }
          arBatch["u360sched_" + stud_id] = [method, params];
        }
        console.log("::callBatch arBatch", arBatch);
        //Назначения сделаны и у нас есть их id
        //Запишем их в студентах на всякей случай
        if (Object.keys(arBatch).length > 0) {
          $this.parent.callBatch(arBatch, (res) => {
            console.log("::callBatch res=", res);
            for (let key in res) {
              let u360sched_id = res[key];
              let [tmp, uid] = key.split("_");
              let index = degree360.students.findIndex((el) => {
                return el.id == uid;
              });
              if (index >= 0) {
                degree360.students[index].sched = u360sched_id;
              }
            }
            //Сохраним оценку360 с записанными id назначений
            $this.competence_saved({ degree360: degree360, index: degree360Index });
            resolve(true);
            if (degree360.flags && degree360.flags.is_tasks != 2) {
              $this.addTasks(degree360, degree360_OLD);
            }
          });
        } else {
          resolve(false);
        }
      });
    },
    /**
     * Задачи не использются
     */
    addTasks(degree360, degree360_OLD) {
      return;

      console.log("::addTasks degree360=", degree360);
      let $this = this;
      let arBatch = {};
      degree360.name = this.parent.restoreQuotes(degree360.name);
      degree360.description = this.parent.restoreQuotes(degree360.description);
      const link = `[url=${$this.parent.appURL}?degree360=${degree360.id}]${degree360.name}[/url]`;
      let message =
        $this.message[$this.lang].degree360_message +
        `<br><a target="_blank" href=${$this.parent.appURL}?degree360=${degree360.id}>${degree360.name}</a>`;
      let description = $this.message[$this.lang].degree360_description + ` ${link}`;
        description += `\n${degree360.description}`
      let title = degree360.name;
      let is_tasks = degree360.flags.is_tasks;
      let experts_ids = [];
      if (!is_tasks) {
        //Индивидуально
        for (let student of degree360.students) {
          for (let expert of student.experts) {
            let uid = expert.id;
            //ищем в назначении до редактирования что бы не назначать второй раз
            const studentWithExpert =
              degree360_OLD &&
              degree360_OLD.students &&
              degree360_OLD.students.find(
                (stud) => uid == stud.experts.some((exp) => exp.id == uid)
              );
            if (studentWithExpert) {
              console.log(":: allready tasked ", studentWithExpert);
              continue;
            }
            experts_ids.push(uid);
            let fields = {
              TITLE: title,
              DESCRIPTION: description,
              DESCRIPTION_IN_BBCODE: "Y",
              RESPONSIBLE_ID: uid,
              AUDITORS: [this.parent.user_current.ID],
              DEADLINE: degree360.date_finish ? degree360.date_finish : "",
              START_DATE_PLAN: degree360.date_start ? degree360.date_start : "",
              END_DATE_PLAN: degree360.date_finish ? degree360.date_finish : "",
            };
            arBatch["task_" + uid + "_" + student.id] = [
              "tasks.task.add",
              { fields: fields },
            ];
            $this.parent.notifyLK(uid, message, $this.parent.user_current);
          }
        }
      } else if (is_tasks == 1) {
        //Одна задача на всех
        for (let student of degree360.students) {
          for (let expert of student.experts) {
            let uid = expert.id;
            //ищем в назначении до редактирования
            const studentWithExpert =
              degree360_OLD &&
              degree360_OLD.students &&
              degree360_OLD.students.find(
                (stud) => uid == stud.experts.some((exp) => exp.id == uid)
              );
            if (studentWithExpert) {
              console.log(":: allready tasked ", studentWithExpert);
              continue;
            }
            experts_ids.push(uid);
            $this.parent.notifyLK(uid, message, $this.parent.user_current);
          }
        }
        let fields = {
          TITLE: title,
          DESCRIPTION: description,
          DESCRIPTION_IN_BBCODE: "Y",
          RESPONSIBLE_ID: this.parent.user_current.ID,
          AUDITORS: experts_ids,
          DEADLINE: degree360.date_finish ? degree360.date_finish : "",
          START_DATE_PLAN: degree360.date_start ? degree360.date_start : "",
          END_DATE_PLAN: degree360.date_finish ? degree360.date_finish : "",
        };
        arBatch["task_" + this.parent.user_current.ID] = [
          "tasks.task.add",
          { fields: fields },
        ];
      }
      
      if (experts_ids.length > 0) {
        //Ставим задачи
        $this.parent.callBatch(arBatch, (res) => {
          console.log("::arBatch res=", res);
        });
      }
    },
    saveDegree360(degree360) {
      let $this = this;
      return new Promise((resolve) => {
        const findList = degree360?.students ? JSON.stringify(degree360.students.map((el) => el.id)) : "";
        let method = "entityItemAdd";
        let params = {
          ENTITY: "uni_degree360",
          PREVIEW_TEXT: degree360.agreeID ? JSON.stringify(degree360.agreeID) : "", //согласующие
          NAME: degree360.name,
          DATE_ACTIVE_FROM: dayjs(degree360.date_start).format("YYYY-MM-DD"),
          DATE_ACTIVE_TO: dayjs(degree360.date_finish).format("YYYY-MM-DD"),
          ACTIVE: degree360.approved, //опубликовано Y|N
          PROPERTY_VALUES: {
            flags: JSON.stringify(degree360.flags),
          },
          DETAIL_PICTURE:'',
          STUFF: findList,
        };
        if(degree360.flags.is_self){
          params.DETAIL_PICTURE=degree360.students.map((el) => el.id).join(',');
        }

        if (degree360.id) {
          params.ID = parseInt(degree360.id);
          method = "entityItemUpdate";
        }
        if(degree360.comps){
          for(let comp of degree360.comps){
            for(let ind of comp.indicators){
              ind.name = this.parent.replaceQuotes(ind.name);
            }
          }
        }
        // console.log(":1: saveDegree360 params=", params);
        var url = $this.parent.bx24Init({ AJAX_ACTION: method, params: params });
        $this.parent.bx24params.DETAIL_TEXT = JSON.stringify(degree360);
        if(degree360.comps){
          for(let comp of degree360.comps){
            for(let ind of comp.indicators){
              ind.name = this.parent.restoreQuotes(ind.name);
            }
          }
        }
        // console.log(":2: saveDegree360 DETAIL_TEXT=", $this.parent.bx24params.DETAIL_TEXT);
        $this.parent.b24POST( url, $this.parent.bx24params, function (res) {
            console.log("::saveDegree360 res=", res);
            if (res.result) {
              let id = 0;
              if (params.ID) {
                id = params.ID;
              } else {
                id = res.result;
              }
              resolve(id);
            } else {
              resolve(false);
            }
          },
          "json"
        );
      });
    },
    competence_close() {
      this.add_modal = false;
    },
   
  },
  components: {
    degree360Add,
    // degree360TaskAdd,
    Degree360StatusList,
    // Degree360StatusFill,
    Comments,
    apexcharts: VueApexCharts,
  },
};
</script>
<style scoped>
.myselect:hover {
  filter: brightness(70%);
}
</style>
