<template>
  <div v-bind:class="[{'for_mobile' : parent.isMobile, 'for_desktop' : !parent.isMobile}]">
    <div v-if="test">

      <div v-if="parent.isMobile" class="row justify-between items-end text-left" >
        <div class="col-12 q-mt-xs">
          <div class="q-gutter-x-sm">
            <q-btn color="positive" no-caps flat round icon="transit_enterexit"  @click="$emit('goback', goback);"/>
            <q-btn unelevated color="primary" >
              {{message[lang].title}} <span v-if="parent.showSysInfo=='Y' && (parent.isModer || parent.isAdmin)"> - {{test.ID}}</span>
            </q-btn>
            <span v-if="!nav && test && test.ACTIVE !== 'Y'" class="text-danger h5">({{message[lang].notpublished}})</span>&ensp;
            <q-img  style="cursor: pointer; height: 24px; width: 19px;"  src="img/card/cours/secondary/calendar.svg"/> {{parent.formatDate(test)}}
            <span class="h5 text-positive">{{pointsShow}}</span>
          </div>
        </div>
      </div>
      <div v-else v-bind:class="[{'margin-top-30' : parent.isAdmin||parent.isModer, 'margin-top_15' : !parent.isAdmin&&!parent.isModer }]">
        <div class="row items-justify items-center text-left">
          <div class="col-5 text-left q-pl-md">
            <q-btn color="positive" no-caps flat rounded icon="transit_enterexit" :label="message[lang].goback" @click="$emit('goback', goback);"/>
            <div class="q-my-sm">
              <q-btn unelevated color="warning" >
                {{message[lang].title}} <span v-if="parent.showSysInfo=='Y' && (parent.isModer || parent.isAdmin)"> - {{test.ID}}</span>
              </q-btn>
              <span v-if="!nav && test && test.ACTIVE !== 'Y'" class="text-danger h5">({{message[lang].notpublished}})</span>&ensp;
              <q-img  style="cursor: pointer; height: 24px; width: 19px;"  src="img/card/cours/secondary/calendar.svg"/>
              {{parent.formatDate(test)}}
              <span class="h5 text-positive">{{pointsShow}}</span>
            </div>
          </div>

        </div>
        <div class="text-right q-pr-md text-positive">
          {{message[lang].direct_link}}:
          <input style="border: none; background: none; border-radius: 15px"
                 class="text-positive"
                 v-on:focus="$event.target.select()"
                 :ref="'clone'+test.ID"
                 readonly
                 :value="parent.appURL+'?test='+test.ID"/>

          <q-btn flat round color="positive" icon="content_copy"
                 @click="copy(test.ID)">
          </q-btn>
        </div>
      </div>

      <div v-if="parent.isMobile" class="h4 q-mb-md">{{test.NAME}}</div>
      <div v-else class="h3 q-mb-md">{{test.NAME}}</div>
      <!-- Навигация по курсу -->
      <div v-if="nav && showNav" class="q-mb-md">
        <div class="row justify-end q-my-md" v-if="!testItem.tree">
          <div class="col-5 text-right">
            <q-btn-dropdown flat no-caps rounded color="primary" :label="message[lang].course_navigation">
              <q-list>
                <q-item v-for="element in nav" :key="element.id"  v-close-popup :clickable="!element.dsbld" @click="viewItem(element)" :disable="element.stage === 'D'">
                  <q-item-section>
                    <q-item-label>
                      <span v-if="!element.dsbld">{{message[lang][element.type]}}:{{parent.showSysInfo=='Y'?element.id:''}}</span>
                      <span :class="element.dsbld?'text-positive':'text-accent'"> {{element.name}}</span>
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </div>
        </div>
        <!-- Кнопки завершения/перехода   -->
        <div class="row justify-between">
          <div class="col-md-4 col-xs-12 text-left">
            <q-btn unelevated rounded no-caps :disable="!isNavFinished && testItem.alw_next==2" @click="completeTheCourse" color="warning" :label="message[lang].complete_the_course"/>
          </div>
          <div class="col-md-4 col-xs-12 text-left" v-if="!testItem.tree">
            <q-btn unelevated rounded no-caps :disable="!isLastNav || !daysToTestPassed && testItem.alw_next==2" @click="getNextNav" color="primary" :label="message[lang].get_next"/>
          </div>
          <div class="col-md-4 col-xs-12 text-left">
            <q-btn unelevated rounded no-caps :disable="!!testMessageResult || !isTestRun" @click="stopTest"  color="warning" :label="message[lang].module_complete"/>
          </div>
        </div>

      </div>


    <q-separator/>

      <div class="row q-my-sm">
        <div class="col-2" v-if="!parent.isMobile && test.DETAIL_PICTURE">
          <q-img :src="test.DETAIL_PICTURE" />
        </div>
<!--        <div class="col-2" v-else-if="!parent.isMobile && !parent.isJSON(test.DETAIL_TEXT)">-->
<!--          <q-img :src="test.DETAIL_TEXT" />-->
<!--        </div>-->
        <div class="col-xs-12 col-md-10 q-gutter-y-xs q-pl-md">
          <div><span class="text-positive h4">{{message[lang].level}}:</span> <span v-if="test && test.PROPERTY_VALUES" class="body-0">{{level_options[lang][test.PROPERTY_VALUES.level]}}</span></div>
          <div><span class="text-positive h4">{{message[lang].thematics}}:</span> <span v-if="test && test.PROPERTY_VALUES" class="body-0">{{test.PROPERTY_VALUES.thematics}}</span></div>
          <div><span class="text-positive h4">{{message[lang].description}}:</span> <span v-if="test && test.PROPERTY_VALUES" class="body-0">
<!--            {{test.PROPERTY_VALUES.description}}-->
          <q-input
            v-model="test.PROPERTY_VALUES.description"
            type="textarea"
            borderless
            readonly
            class="h4"
            style="width: 100%"
          />
          </span></div>
          <div v-if="competence"><span class="positive h4">{{message[lang].course_comps}}</span>:
            <q-chip color="accent" outline v-for="(item,index) in competence" :key="index">
              {{item.name}}
            </q-chip>
          </div>
          <div><span class="text-positive h4">{{message[lang].passing_score}}:</span> <span class="body-0">
            {{test.PROPERTY_VALUES.score}} <span>% {{message[lang].right_quest}}</span></span>
          </div>
          <div><span class="h4">{{message[lang].time_to_test}}</span>: <span v-if="test && test.PROPERTY_VALUES" class="body-0">{{test.PROPERTY_VALUES.time}}</span></div>
          <div v-if="test.PROPERTY_VALUES.flags.att">
            {{message[lang].count_attempts}}: {{countTestAttempts > test.PROPERTY_VALUES.flags.att ? test.PROPERTY_VALUES.flags.att : countTestAttempts}}/{{test.PROPERTY_VALUES.flags.att}}
          </div>
        </div>
      </div>

      <!-- Кнопки Старт/продолжить-->
      <div class="row q-my-sm items-center">
        <div class="col-xs-12 col-md-2 text-center">
          <q-btn v-if="testMessageResult" color="secondary" :label="message[lang].goback" no-caps flat rounded icon="transit_enterexit" @click="$emit('goback', goback);"/>
          <q-btn v-else-if="!isTestRun"
                 @click="startTest"
                 :disabled="test?.isLoaded!==true || !isMoreTestAttempts || !isQuestions || isFilesLoading || parseInt(daysToTestValid) !== 0 && daysToTestEnded || !availPeriods"
                 :loading="isFilesLoading"
                 rounded unelevated color="primary"
                 :label="message[lang].start"/>
          <q-btn v-else @click="stopTest"  rounded unelevated color="warning" :label="message[lang].stop"/>
        </div>


        <div class="col-md-10 col-xs-12 q-gutter-y-sm q-pa-xs" v-if="!isTestRun && (daysToTestEnded!==null || !isMoreTestAttempts)">
          <q-banner rounded class="bg-grey-1 h3" v-if="(daysToTestPassed || daysToTestEnded) && !isTestRun && !countOpens">
            <template v-slot:avatar><q-icon name="notification_important" :color="daysToTestPassed?'primary':'warning'" /></template>
            <span class="h3 text-primary" v-if="daysToTestPassed">{{message[lang].passed}}</span>
            <span class="h3 text-warning" v-else>{{message[lang].nopassed}}</span>
          </q-banner>
          <q-banner rounded class="bg-grey-1 h3 text-warning" v-if="(parseInt(daysToTestValid) !== 0 && daysToTestEnded) && !isTestRun">
            <template v-slot:avatar><q-icon name="notification_important" color="warning" /></template>
            <span class="h3 text-warning">{{message[lang].days_to_next}}: {{daysToTestValid}}</span>
          </q-banner>
          <q-banner rounded class="bg-grey-1 h3 text-warning" v-if="!isMoreTestAttempts && test.PROPERTY_VALUES.flags.att">
            <template v-slot:avatar><q-icon name="block" color="warning" /></template>
            <span class="h3 text-warning">{{message[lang].no_more_attempts}}: {{test.PROPERTY_VALUES.flags.att}}</span>
          </q-banner>

        </div>
      </div>

    <div v-if="isTestRun && currentTest">
      <div class="row items-center justify-between">
        <div class="col-xs-12 col-md-4 text-left">
          <q-btn outline round  color="primary" icon="chevron_left" @click="getPrev" :disable="currentTest.currentQuest <= 0 || currentTest.no_prev==='Y'"/>
          {{(currentTest.currentQuest+1)}}/{{questToDisplay}}
          <q-btn outline round  color="primary" icon="chevron_right" @click="getNext" :disable="(currentTest.currentQuest+1) >= questToDisplay"/>
        </div>
        <div class="col-xs-12 col-md-4 text-center h4" v-if="currentTest.show_results == 3">
          {{message[lang].pc_right}} {{currentTest.pcRight}}%
          <div v-if="currentTest.isPassed" class="text-secondary">{{message[lang].test_passed}}</div>
          <div v-else class="text-warning">{{message[lang].no_passed_yet}}</div>

        </div>
        <div class="col-xs-12 col-md-4 text-right" >
         <span v-if="test.PROPERTY_VALUES.flags.show_time"> {{message[lang].time_left}}: <span class="h4 text-primary">{{showTimeLeft()}}</span> </span>
        </div>
      </div>

      <q-linear-progress color="primary" size="xs" :value="(currentTest.currentQuest+1)/questToDisplay" class="q-mt-md" />

        <q-card v-bind:class="[{'cat-card-mobile' : parent.isMobile, 'cat-card' : !parent.isMobile}]">
          <!--  Вопрос        -->
          <q-card-section class="h4">
            <div class="row items-center justify-between">
              <div class="col-xs-12 col-md-7" v-if="currentTest.questions[currentTest.currentQuest].type !== 'sub'">
                <span v-html="parent.showBase64Text(currentTest.questions[currentTest.currentQuest].text)"></span>
              </div>
              <div v-if="currentTest.questions[currentTest.currentQuest].weight">
                  <span class="h5">{{message[lang].question_weight}}:</span> {{currentTest.questions[currentTest.currentQuest].weight}}
                </div>
              <!-- Если есть блоки -->
              <div v-if="currentTest.flags.block" class="positive h5">
                {{message[lang].q_title_block}}: {{showBlock(currentTest.questions[currentTest.currentQuest].block)}}
              </div>
              <div class="col-xs-12 col-md-5 text-right h5">
                <div v-if="showAlertForQuestion" class="text-warning">
                  {{message[lang].alertForQuestion}}
                </div>
              </div>
            </div>
          </q-card-section>

          <q-separator/>
          <!--  Подсказка к вопросу        -->
          <q-card-section class="positive h4" horizontal>
            <q-card-section>

              <!-- feedback -->
              <div class="q-py-md" v-if="currentTest.show_results == 3 && currentTest.questions[currentTest.currentQuest].fb">
                <span v-if="currentTest.questions[currentTest.currentQuest].type === 'opn' || currentTest.questions[currentTest.currentQuest].type === 'sub'" class="positive h5 q-mr-sm">
                  {{' '}}
                </span>
                <span v-else class="positive h4 q-mr-sm">
                  {{message[lang].feedback_placeholder}}:
                </span>
                <span class="text-secondary" v-if="allRight===true && currentTest.questions[currentTest.currentQuest].fb.right">
                  {{currentTest.questions[currentTest.currentQuest].fb.right}}
                </span>
                <span class="text-warning" v-else-if="allRight===false && currentTest.questions[currentTest.currentQuest].fb.left">
                  {{currentTest.questions[currentTest.currentQuest].fb.left}}
                </span>
              </div>

                <span v-if="currentTest.questions[currentTest.currentQuest].type === 'seq'">
                    {{message[lang].seq}}
                </span>
                <span v-else-if="currentTest.questions[currentTest.currentQuest].type === 'eqw'">
                    {{message[lang].eqw}}
                </span>
                <span v-else-if="currentTest.questions[currentTest.currentQuest].type === 'opn'">
                    {{message[lang].opn}}
                </span>
                <span v-else-if="currentTest.questions[currentTest.currentQuest].type === 'sub'">
                    {{message[lang].sub}}
                </span>
                <span v-else>
                  {{message[lang].selectAny}}
<!--                    <span v-if="isMulty">{{message[lang].selectAny}}</span>-->
<!--                    <span v-else>{{message[lang].selectOne}}</span>-->
                </span>
            </q-card-section>

              <q-img class="col-4"
                v-if="currentTest.questions[currentTest.currentQuest].data"
                :src="currentTest.questions[currentTest.currentQuest].data"
              />
          </q-card-section>

          <!--  Ответы        -->
          <q-card-section>
            <div class="row items-center h4 q-pb-lg">
                <div class="col-xs-12 col-md-8 text-left" v-if="!currentTest.questions[currentTest.currentQuest].type || currentTest.questions[currentTest.currentQuest].type === 'qa'">
                    <q-list separator>
                        <q-item clickable v-ripple
                           v-for="(item,index) in currentTest.answers[currentTest.questions[currentTest.currentQuest].value]"
                          :key="index"
                          @click="checkTogle(index)"
                        >
                          <q-item-section avatar>
                            <q-icon size="lg" v-if="item.checked" name="done" color="secondary"/>
                          </q-item-section>

                          <q-item-section>
                            <q-item-label>{{item.text}}</q-item-label>
                          </q-item-section>

                          <q-item-section v-if="item.file && item.file.href">
                            <q-img :src="item.file.href" />
                          </q-item-section>
                          <q-item-section v-else-if="item.data">
                            <q-img :src="item.data" />
                          </q-item-section>
                        </q-item>
                    </q-list>
                </div>
                <div class="col-12" v-else-if="currentTest.questions[currentTest.currentQuest].type === 'eqw'">
                  <div class="row items-center">

                    <div class="col-5 text-left q-px-none q-mx-none">
                        <draggable :group="{ name: 'answers', pull: 'clone', put: false }" :sortable="false" @change="seq_Log" v-model="currentTest.answers[currentTest.questions[currentTest.currentQuest].value]" >
                          <transition-group>
                              <div class="list-item-drag" v-for="item in currentTest.answers[currentTest.questions[currentTest.currentQuest].value]" :key="item.value">
                                <q-img v-if="item.file && item.file.href" :src="item.file.href" style="height: 150px" fit="scale-down"/>
                                <span v-else>{{item.text }}</span>
                              </div>
                          </transition-group>
                        </draggable>
                    </div>
                    <div class="col-1 text-center q-px-none q-mx-none">
                      <q-icon name="sync_alt" class="text-primary" style="font-size: 2.1em;" />
                    </div>
                  <div class="col-5 text-left q-px-none q-mx-none">
                    <draggable :group="{ name: 'answers2', pull: 'clone', put: false }" :sortable="false" @change="seq_Log" v-model="currentTest.answers2[currentTest.questions[currentTest.currentQuest].value]" >
                      <transition-group>
                        <div class="list-item-drag" v-for="item in currentTest.answers2[currentTest.questions[currentTest.currentQuest].value]" :key="item.value">
                          <q-img  v-if="item.file2 && item.file2.href" :src="item.file2.href" style="height: 150px" fit="scale-down"/>
                          <span v-else>{{item.text2 }}</span>
                        </div>
                      </transition-group>
                    </draggable>
                  </div>
                  </div>
                </div>

              <div class="col-12" v-else-if="currentTest.questions[currentTest.currentQuest].type === 'seq'">
                <div class="row items-center">
                  <div class="col-5 text-left q-px-none q-mx-none">
                    <draggable :group="{ name: 'people', pull: 'clone', put: false }" :sortable="false" @change="seq_Log" v-model="currentTest.answers[currentTest.questions[currentTest.currentQuest].value]" >
                      <transition-group>
                        <div class="list-item-drag" v-for="item in currentTest.answers[currentTest.questions[currentTest.currentQuest].value]" :key="item.value">
                          <q-img  v-if="item.file && item.file.href" :src="item.file.href" style="height: 150px" fit="scale-down"/>
                          <div>{{item.text }}</div>
                        </div>
                      </transition-group>
                    </draggable>
                  </div>
                </div>
              </div>
            <!-- Ответ на открытый вопрос  -->
              <div class="col-12" v-else-if="currentTest.questions[currentTest.currentQuest].type === 'opn'">
                <q-input
                  v-model="currentTest.answers[currentTest.questions[currentTest.currentQuest].value]"
                  type="textarea"
                  borderless
                  style="padding-left:10px;  border-radius: 25px !important; background-color: #F2F6FF"
                  class="body-0"
                  @update:model-value="saveCurrentTest()"
                  debounce="1000"
                />

                <LoadImages v-if="showLoadImages"
                  v-bind:test_id="test.ID"
                  v-bind:lang="lang"
                  v-bind:parent="parent"
                  v-bind:curquest="parent.myEx(currentTest.questions[currentTest.currentQuest].text)"
                  v-bind:imgFilesProp="imgFiles"
                  @save_load_images="saveImages"
                />

              </div>
              <div class="col-12" v-else-if="currentTest.questions[currentTest.currentQuest].type === 'sub'">
                <div class="q-gutter-md row items-start">
                  <span v-for="(item,index) in showSubstitution" :key="index">
                    {{item}}
                    <input  v-if="index+1 < showSubstitution.length"
                            type="text"
                            v-model="currentTest.answers2[currentTest.questions[currentTest.currentQuest].value][index]"
                            @blur="saveCurrentTest()"
                    >
                  </span>
                </div>
              </div>
            </div>

          </q-card-section>

          <q-card-section v-if="parent.isMobile" style="height: 300px;">
            <q-separator/>
          </q-card-section>
        </q-card>

      </div>
<!-- style="height: 300px;"-->
    <div v-else>
      <div class="row justify-center">
        <div class="col-xs-12 col-md-8 text-center h4 text-primary q-my-md">
          <div v-if="testMessage">{{testMessage}}</div>
          <div v-if="testMessageResult" v-html="testMessageResult"></div>
        </div>
        <div class="col-xs-12 col-md-8 text-center h5 q-my-sm">
          {{message[lang].where_look_rezult}}
        </div>
        <div v-if="daysToTestPassed && test.PROPERTY_VALUES.flags.issue_cert" class="col-xs-12 col-md-8 text-center text-secondary h5 q-my-sm">
          {{message[lang].where_look_cert}}
        </div>
      </div>
    </div>

      <Comments v-if="test.PROPERTY_VALUES.flags.comments_available"
        v-bind:lang="lang"
        v-bind:entity-id="`test_${test.ID}`"
        v-bind:user_current="user_current"
        v-bind:parent="parent"
        v-bind:mode="'list'"
        @save_btn="saveComment"
      />

    </div>
    <div v-else class="text-center">
      <q-spinner-oval color="text-blue-5" size="48px"/>
    </div>

    <q-dialog v-model="showAlertQuestion" position="top" persistent>
      <q-card style="width: 400px">
        <q-card-section class="row items-center no-wrap">
          <div class="h3 text-warning">
            {{message[lang].alertForQuestion}}
          </div>

          <q-space />

          <q-btn outline round  color="primary" icon="chevron_right" @click="currentTest.currentQuest++; showAlertForQuestion=''; showAlertQuestion=false"/>
        </q-card-section>
      </q-card>
    </q-dialog>



  </div>
</template>

<script>
    import { VueDraggableNext } from 'vue-draggable-next'
    import dayjs from "dayjs";
    import Comments from './Comments.vue';
    import LoadImages from "src/LoadImages";

    export default {
        props:{
          parent:Object,
          back:String,
          lang:String,
          testItem:Object,
          user_current:Object
        },
        data: function () {
            return {
              pointsShow:'',
              competence:'',
              allRight:null,
              countTestAttempts:0,
              imgFiles:[],
              showLoadImages:true,
              opnFiles:{},
              textDone:'OK',
              showDone:false,
              showAlertQuestion:false,
              showNav:true,
              goback:'',
              test:null,
              showAlertForQuestion:false,
                selectedCursor:'pointer',
                listFiles:[],
                isLoading:false,
                isFilesLoading:false,
                currentTest:null,
                testMessage:'',
                testMessageResult:'',
                countOpens:0,
                daysToTestPassed:null,
                daysToTestEnded:null,
                daysToTestValid:0,
                isTestRun:false,
                timerId:null,
                nav:null,
                course_id:null,
              userCourse_id:'',
                level_options: {
                    ru: {
                        low:'легкий',
                        moderate:'средний',
                        high:'сложный',
                    },
                    en: {
                        low: 'low',
                        moderate: 'moderate',
                        high: 'high',
                    },
                  ua: {
                    low: 'легкий',
                    moderate: 'середній',
                    high: 'складний',
                  },
                  fr:{
                    low: 'faible',
                    moderate: 'moyen',
                    high: 'fort',
                  },
                  it: {
                    low: 'basso',
                    moderate: 'intermedio',
                    high: 'alto',
                  },
                  tr: {
                    low:'kolay',
                    moderate:'orta',
                    high:'karmaşık',
                  },
                  de: {
                    low: 'niedrig',
                    moderate: 'mäßig',
                    high: 'hoch',
                  },
                  pl: {
                    low: 'lekki',
                    moderate: 'średni',
                    high: 'trudny',
                  },
                  pt: {
                    low:'fácil',
                    moderate: 'médio',
                    high:'difícil',
                  },
                  es: {
                    low:'fácil',
                    moderate:'medio',
                    high: 'difícil',
                  },
                  vn: {
                    low: 'thấp',
                    moderate: 'vừa phải',
                    high: 'cao',
                  },
                },
                message:{
                  ru:{
                    course_comps:"Компетенции",
                    where_look_cert:"Сертификат можно получить в левом меню в 'Мои сертификаты'",
                    where_look_rezult:"Результаты можно посмотреть в левом меню 'Мои тесты' или 'Мои курсы', если тест пройден в рамках курса, а также в Отчетах",
                    feedback_placeholder:"Обратная связь",
                    not_published_yet:"Тест в настоящее время не опубликован",
                    no_more_attempts:"Использовано максимальное количество попыток",
                    max_attempts:"Макс. количество попыток",
                    count_attempts:"Количество попыток",
                    max_attempts_plc:"0-без ограничений",
                    module_complete:"Завершить",
                    course_navigation:"Навигация по курсу",
                    all_req_title:"Внимание!",
                    all_req_message:"Этот вопрос требует обязательного ответа.",
                    all_req_cancel:"Понятно",

                    q_title_block:"Блок",

                    comment_saved:"Оставлен комментарий",
                    textDone_copy:"Скопировано",
                    title_stop_test:"Завершить тест?",
                    message_stop_test:"Внимание! Тест будет завершен, результаты тестирования сохранены.",
                    direct_link:"Прямая ссылка",
                    get_next:"Перейти к следующему",
                    complete_the_course:"Курс пройден. Завершить курс.",
                    sub:"Вставьте правильные слова, цифры, значения",
                    pc_right:"Правильных ответов",
                    no_passed_yet:"тест пока не пройден",
                    test_passed:"тест пройден",
                    testAllreadyPassed:"Вы уже проходили этот тест. Результат можно посмотреть в личном кабинете. Повторное прохождение заменит результаты Вашего тестирования.",
                    alertForQuestion:"Неверный ответ",
                    test_start:"начал прохождение теста",
                    test_end:"закончил прохождение теста",
                      right_quest:" правильных ответов",
                      question_weight:"Вес вопроса",
                      responsible_assigned:"Вы назначены ответственным за вопрос",
                      responsible_assigned2:"Для проверки ответа, пожалуйста, откройте «Личный кабинет» вкладку «Мои проверки», проверьте корректность ответа и запишите оценку.",
                      opn:"Напишите свой ответ",
                      test_result_opn:"В тесте присутствуют открытые вопросы.<br>Окончательный результат прохождения теста станет известен после их проверки.",
                        seq:"Последовательность. Перетащите мышкой элементы списка.",
                        eqw:"Соответствие. Перетащите мышкой элементы списка.",
                        title:"Тест",
                        goback:"Вернуться",
                        loading:"Загрузка",
                        remove:"Удалить",
                        thematics:"Тематика",
                        description:"Описание",
                        level:"Уровень сложности",
                        start:"Начать тест",
                        continue:"Продолжить тест",
                        stop:"Закончить тест",
                        time_left:"Осталось времени",
                        prev:'< Предыдущий',
                        next:"Следующий >",
                        selectOne:"Выберите один правильный ответ",
                        selectAny:"Выберите правильные ответы",
                        time_is_up:"Время выполнения теста истекло",
                        test_result:"Результат выполнения теста",
                        question:"Вопрос",
                        answers:"Ответы",
                        passed:"Пройден",
                        nopassed:"Не пройден",
                        days_to_passed:"Прошлый результат тестирования",
                        days_to_next:"До следующего тестирования осталось дней",
                        lesson:"Урок",
                        test:"Тест",
                        passing_score:"Проходной балл",
                        time_to_test:"Время на тест, в минутах",
                        minutes:"минут",
                        alert_title:"Ошибка загрузки теста",
                        alert_message:"Возможно тест был удален. Обратитесь к администратору.",
                        alert_cancel:"Понятно",
                        notpublished:"Не опубликован",
                        right:"правильный",
                    },
                  en:{
                    q_title_block:"Block",
                    course_comps:"Competencies",
                    where_look_cert: "The certificate can be obtained in the left menu in 'My Certificates'",
                    where_look_rezult:"The results can be viewed in the left menu 'My tests' or 'My courses', if the test was passed in the course, and in Reports",
                    feedback_placeholder:"Feedback",
                    not_published_yet: "The test is currently not published",
                    no_more_attempts: "Max attempts used",
                    max_attempts:"Max Attempts",
                    max_attempts_plc:"0-no limit",
                    module_complete: "Complete module",
                    course_navigation:"Course Navigation",
                    all_req_title: "Attention!",
                    all_req_message:"This question requires a mandatory response.",
                    all_req_cancel:"Got it",

                    comment_saved: "Comment saved",
                    direct_link:"Direct link",
                    textDone_copy:"Copied",
                    title_stop_test:"Do you want to end the test?",
                    message_stop_test:"Attention! The test will end and the test results will be saved.",
                    get_next:"Go to the next module",
                    complete_the_course:"The course has been completed. Complete the course.",
                    sub:"Insert the correct words, numbers, values",
                    pc_right: "Correct answers",
                    no_passed_yet:"the test has not been passed yet",
                    test_passed:"test passed",
                    testAllreadyPassed:"You have already passed this test. The result can be viewed in your personal account. Repeated passing will replace the results of your testing.",
                    alertForQuestion: "Incorrect answer",
                    test_start:"started passing the test",
                    test_end: "finished passing the test",
                    right_quest: "correct answers",
                      question_weight: "Question weight",
                      responsible_assigned:"You are assigned the responsibility for the question",
                      responsible_assigned2: "To check the response, please open the 'My Checks' tab in your Personal Account, check the correctness of the response, and write down the rating.",
                      opn: "Write your answer",
                      test_result_opn: "The test contains open questions.<br>The final result of passing the test will be known after their verification.",
                        seq:"Sequence. Click on the list element and hold to drag.",
                        eqw:"Matching. Click on the list element and hold to drag.",
                        title:"Test",
                        goback:"Back",
                        loading:"Loading",
                        remove:"Remove",
                        thematics:"Subject",
                        description:"Description",
                        level:"Difficulty level",
                        start:"Start testing",
                        continue:"Continue testing",
                        stop:"Finish testing",
                        time_left:"Time left",
                        prev:'< Previous',
                        next:"Next >",
                        selectOne:"Select one correct answer",
                        selectAny:"Choose the correct answers",
                        time_is_up:"No time left",
                        test_result:"Testing result",
                        question:"Question",
                        answers:"Answers",
                        passed:"Passed",
                        nopassed:"Not passed",
                        days_to_passed:"Previous testing result",
                        days_to_next:"Days left to the next testing",
                        lesson:"Lesson",
                        test:"Test",
                        passing_score:"Admission score",
                        time_to_test:"Testing time, minutes",
                        minutes:"minutes",
                        alert_title:"Test loading error",
                        alert_message:"Test must have been deleted. Please contact your administrator.",
                        alert_cancel:"Got it",
                        notpublished:"Not published",
                        right:"correct",
                    },
                  ua:{
                    q_title_block:"Блок",
                    course_comps:"Компетенції",
                    where_look_cert:"Сертифікат можна отримати в лівому меню в 'Мої сертифікати'",
                    where_look_rezult:"Результати можна переглянути в лівому меню 'Мої тести' або 'Мої курси', якщо тест пройдено в рамках курсу, а також у Звітах",
                    feedback_placeholder:"зворотній зв'язок",
                    not_published_yet:"Тест в даний час не опублікований",
                    no_more_attempts:"Використана максимальна кількість спроб",
                    max_attempts:"Макс. кількість спроб",
                    max_attempts_plc:"0-без обмежень",
                    module_complete:"Завершити модуль",
                    course_navigation: "Навігація за курсом",
                    all_req_title:"Увага!",
                    all_req_message:"Це питання вимагає обов'язкової відповіді.",
                    all_req_cancel:"Зрозуміло",

                    comment_saved:"Залишений коментар",
                    direct_link:"Пряме посилання",
                    textDone_copy:"Скопійовано",
                    title_stop_test:"Завершити тест?",
                    message_stop_test:"Увага! Тест буде завершено, результати тестування збережені.",
                    get_next: "Перейти до наступного модуля",
                    complete_the_course: "Курс пройдено. Завершити курс.",
                    sub: "вставте правильні слова, цифри, значення",
                    pc_right: "Правильних відповідей",
                    no_passed_yet: "тест поки не пройдено",
                    test_passed: "тест пройдено",
                    testAllreadyPassed: "Ви вже проходили цей тест. Результат можна подивитися в особистому кабінеті. Повторне проходження замінить результати вашого тестування.",
                    alertForQuestion: "Невірна відповідь",
                    test_start: "почав проходження тесту",
                    test_end: "закінчив проходження тесту",
                    right_quest: "правильних відповідей",
                    question_weight: "Вага питання",
                    responsible_assigned: "ви призначені відповідальним за питання",
                    responsible_assigned2: "для перевірки відповіді, будь ласка, відкрийте» Особистий кабінет вкладку 'Мої перевірки', перевірте коректність відповіді і запишіть оцінку.",
                    opn: "напишіть свою відповідь",
                    test_result_opn: "у тесті присутні відкриті питання.< br>остаточний результат проходження тесту стане відомий після їх перевірки.",
                    seq:"Послідовність. Перетягніть мишкою елементи списку.",
                    eqw:"Відповідність. Перетягніть мишкою елементи списку.",
                    title:"Тест",
                    goback:"Повернутися",
                    loading:"Завантаження",
                    remove:"Видалити",
                    thematics:"Тематика",
                    description:"Опис",
                    level:"Рівень складності",
                    start:"Почати тест",
                    continue:"Продовжити тест",
                    stop:"Закінчити тест",
                    time_left:"Залишилося часу",
                    prev:'< Попередній',
                    next:"Наступний >",
                    selectOne:"Виберіть одну правильну відповідь",
                    selectAny:"Wähle die richtigen Antworten",
                    time_is_up:"Час виконання тесту минув",
                    test_result:"Результат виконання тесту",
                    question:"Питання",
                    answers:"Відповіді",
                    passed:"Пройдено",
                    nopassed:"Не пройдено",
                    days_to_passed:"Минулий результат тестування",
                    days_to_next:"До наступного тестування залишилося днів",
                    lesson:"Урок",
                    test:"Тест",
                    passing_score:"Прохідний бал",
                    time_to_test:"Час на тест, в хвилинах",
                    minutes:"хвилин",
                    alert_title:"Помилка завантаження тесту",
                    alert_message:"Можливо тест був видалений. Зверніться до адміністратора.",
                    alert_cancel:"Зрозуміло",
                    notpublished:"Не опублікований",
                    right:"правильний",
                  },
                  fr:{
                    q_title_block: "Bloc",
                    course_comps: "Compétences",
                    where_look_cert: "Le certificat peut être obtenu dans le menu de gauche dans 'Mes Certificats'",
                    where_look_rezult: "Les résultats sont visibles dans le menu de gauche 'Mes tests' ou 'Mes cours', si le test est réussi dans le cadre du cours, ainsi que dans les Rapports",
                    feedback_placeholder: "Commentaires",
                    not_published_yet: "Le test n'est actuellement pas publié",
                    no_more_attempts: "Tentatives max. utilisées",
                    max_attempts:"Tentatives max",
                    max_attempts_plc:"0-pas de limite",
                    module_complete: "Module complet",
                    course_navigation:"Navigation du cours",
                    all_req_title: "Attention!",
                    all_req_message:"Cette question nécessite une réponse obligatoire.",
                    all_req_cancel:"Compris",

                    comment_saved: "Commentaire enregistré",
                    direct_link:"Lien direct",
                    textDone_copy:"Copié",
                    title_stop_test: "Arrêter le test?",
                    message_stop_test:"Attention! Le test va se terminer et les résultats du test seront enregistrés.",
                    complete_the_course: "Cours terminé. Terminer le cours.",
                    sub: "Insérer les bons mots, chiffres, valeurs",
                    pc_right: "Bonnes réponses",
                    no_passed_yet:"test non encore réussi",
                    test_passed:"test réussi",
                    testAllreadyPassed: "Vous avez déjà passé ce test. Le résultat peut être vu dans votre compte personnel. Un nouveau passage remplacera les résultats de Vos tests.",
                    alertForQuestion:"mauvaise réponse",
                    test_start: "a commencé le test",
                    test_end: "terminé le test",
                    right_quest: "réponses correctes",
                    question_weight: "Coefficient",
                    responsible_assigned:"Vous êtes désigné comme responsable de cette question. ",
                    responsible_assigned2: "Pour vérifier la réponse, ouvrez l’onglet 'Mes contrôles' dans votre tableau de bord, vérifiez la justesse de la réponse et insérez votre note.",
                    opn: "Entrer la réponse ici.",
                    test_result_opn: "Le test contient des questions ouvertes.<br>Le résultat final sera connu après leur vérification.",
                    seq:"Séquence. Cliquer et déposer la réponse au bon endroit.",
                    eqw:"Correspondance. Cliquer sur la réponse et la faire glisser.",
                    title:"Test",
                    goback:"Retour",
                    loading:"Chargement",
                    remove:"Retirer",
                    thematics:"Sujet",
                    description:"Description",
                    level:"Niveau de difficulté ",
                    start:"Commencer le test",
                    continue:"Poursuivre le test",
                    stop:"Terminer le test",
                    time_left:"Temps restant",
                    prev:'< Précédent',
                    next:"Suivant >",
                    selectOne:"Choisissez la bonne réponse",
                    selectAny:"Choisir les bonnes réponses",
                    time_is_up:"Temps épuisé",
                    test_result:"Résultat",
                    question:"Question",
                    answers:"Réponses",
                    passed:"Réussi",
                    nopassed:"À refaire",
                    days_to_passed:"Dernier test réussi",
                    days_to_next:"Jours restants jusqu’au prochain test",
                    lesson:"Leçon",
                    test:"Test",
                    passing_score:"Résultat requis pour l’admission",
                    time_to_test:"Durée du test (en minutes)",
                    minutes:"minutes",
                    alert_title:"Erreur de chargement du test",
                    alert_message:"Le test a probablement été effacé. Veuillez contacter votre administrateur.",
                    alert_cancel:"Compris",
                    notpublished:"Non publié",
                    right:"correct",
                  },
                  it:{
                    q_title_block:"blocco",
                    course_comps: "Competenze",
                    where_look_cert: "Il certificato può essere ottenuto nel menu a sinistra in 'I miei certificati'",
                    where_look_rezult: "I risultati possono essere visualizzati nel menu a sinistra 'I miei test' o 'I miei corsi', se il test viene superato come parte del corso, così come nei Report",
                    feedback_placeholder:"feedback",
                    not_published_yet: "Le test n'est actuellement pas publié",
                    no_more_attempts: "Numero massimo di tentativi utilizzati",
                    max_attempts:"Numero massimo di tentativi",
                    max_attempts_plc:"0-nessun limite",
                    module_complete: "Modulo completo",
                    course_navigation:"Navigazione rotta",
                    all_req_title: "Attenzione!",
                    all_req_message:"Questa domanda richiede una risposta obbligatoria.",
                    all_req_cancel:"Capito",

                    comment_saved: "Commento salvato",
                    direct_link:"Collegamento diretto",
                    textDone_copy:"Copiato",
                    title_stop_test: "Interrompi test?",
                    message_stop_test:"Attenzione! Il test terminerà e i risultati del test verranno salvati.",
                    get_next:"Vai al modulo successivo",
                    complete_the_course: "corso completato. Completa il corso.",
                    sub: "inserisci parole, numeri, significati corretti",
                    pc_right:"Risposte corrette",
                    no_passed_yet: "il test non è ancora stato superato",
                    test_passed: "test superato",
                    testAllreadyPassed: "Hai già superato questo test. Il risultato può essere visualizzato nel tuo account personale. Ripetere il passaggio sostituirà i risultati del test.",
                    alertForQuestion: "risposta sbagliata",
                    test_start: "ha iniziato il test",
                    test_end: "finito il test",
                    right_quest: "risposte esatte",
                    question_weight: "Peso domanda",
                    responsible_assigned:"Ti è stata assegnata la responsabilità della domanda",
                    responsible_assigned2: "Per controllare il responso, apri la scheda “I miei controlli” nel tuo Account Personale, controlla la correttezza del responso e scrivi la valutazione.",
                    opn: "Scrivi la tua risposta",
                    test_result_opn: "Il test contiene domande aperte.<br>Il risultato finale del test verrà reso noto dopo la loro verifica.",
                    seq:"Sequenza. Fai click sull’elemento lista e tieni premuto per trascinare.",
                    eqw:"Matching. Fai click sull’elemento lista e tieni premuto per trascinare.",
                    title:"Test",
                    goback:"Indietro",
                    loading:"Caricamento",
                    remove:"Rimuovi",
                    thematics:"Materia",
                    description:"Descrizione",
                    level:"Livello di difficoltà",
                    start:"Inizia test",
                    continue:"Continua test",
                    stop:"Termina test",
                    time_left:"Tempo rimasto",
                    prev:'< Precedente',
                    next:"Successivo >",
                    selectOne:"Seleziona una risposta esatta",
                    selectAny:"Scegli le risposte giuste",
                    time_is_up:"Tempo esaurito",
                    test_result:"Risultato test",
                    question:"Domanda",
                    answers:"Risposte",
                    passed:"Passato",
                    nopassed:"Non passato",
                    days_to_passed:"Risultato test precedente",
                    days_to_next:"Giorni rimasti per il prossimo test",
                    lesson:"Lezione",
                    test:"Test",
                    passing_score:"Punteggio di ammissione",
                    time_to_test:"Tempo test, minuti",
                    minutes:"minuti",
                    alert_title:"Errore caricamento test",
                    alert_message:"Il test deve essere stato eliminato. Contatta il tuo amministratore.",
                    alert_cancel:"D’accordo",
                    notpublished:"Non pubblicato",
                    right:"corretto",
                  },
                  tr:{
                    q_title_block:"Blok",
                    course_comps: "Yetenekler",
                    where_look_cert: "Sertifikayı soldaki 'Sertifikalarım' menüsünden alabilirsiniz",
                    where_look_rezult: "Sonuçlar sol menüdeki 'Testlerim' veya testin kursun bir parçası olarak geçilmesi durumunda 'Kurslarım', menüsünden ve ayrıca Raporlar'dan görüntülenebilir",
                    feedback_placeholder:"Geri bildirim",
                    not_published_yet: "Test şu anda yayınlanmadı",
                    no_more_attempts: "Maksimum deneme kullanıldı",
                    max_attempts:"Maksimum Deneme Sayısı",
                    max_attempts_plc:"0-limit yok",
                    module_complete: "Modülü tamamla",
                    Course_navigation:"Ders Gezintisi",
                    all_req_title: "Dikkat!",
                    all_req_message:"Bu soru zorunlu bir yanıt gerektiriyor.",
                    all_req_cancel:"Anladım",

                    comment_saved: "Yorum kaydedildi",
                    direct_link:"Doğrudan bağlantı",
                    textDone_copy:"Kopyalandı",
                    title_stop_test: "Test Durdurulsun mu?",
                    message_stop_test:"Dikkat! Test sona erecek ve test sonuçları kaydedilecek.",
                    get_next:"Bir sonraki modüle git",
                    complete_the_course:"Kurs tamamlandı. Rotayı tamamlayın.",
                    sub:"Doğru kelimeleri, sayıları, anlamları ekleyin",
                    pc_right: "Doğru cevap",
                    no_passed_yet:"test henüz geçilmedi",
                    test_passed:"test geçildi",
                    testAllreadyPassed:"Bu testi zaten geçtiniz. Sonuç kişisel hesabınızda görüntülenebilir. Tekrarlanan geçiş, testinizin sonuçlarının yerini alacaktır",
                    alertForQuestion: "Yanlış cevap",
                    test_start:"testi geçmeye başladı",
                    test_end: "testi geçerek bitirdi",
                    right_quest: "doğru cevap",
                    question_weight: "Soru ağırlığı",
                    responsible_assigned:"Sorunun sorumluluğu size verildi",
                    responsible_assigned2: "Yanıtı kontrol etmek için lütfen Kişisel Hesabınızdaki 'Çeklerim' sekmesini açın, yanıtın doğruluğunu kontrol edin ve derecelendirmeyi yazın.",
                    opn: "Cevabını yaz",
                    test_result_opn: "Test açık sorular içerir.<br>Testi geçmenin nihai sonucu, doğrulamalarından sonra bilinecektir.",
                    seq:"Sıra. Liste öğesine tıklayın ve sürüklemek için basılı tutun.",
                    eqw:"Eşleştirme. Liste öğesine tıklayın ve sürüklemek için basılı tutun.",
                    title:"Ölçek",
                    goback:"Geri",
                    loading:"Yükleniyor",
                    remove:"Kaldırmak",
                    thematics:"Ders",
                    description:"Açıklama",
                    level:"Zorluk seviyesi",
                    start:"Teste başla",
                    continue:"Teste devam et",
                    stop:"Testi bitir",
                    time_left:"Kalan zaman",
                    prev:'< Önceki',
                    next:"Sonraki >",
                    selectOne:"Bir doğru cevap seçin",
                    selectAny:"Doğru yanıtları seçin",
                    time_is_up:"Zaman kalmadı",
                    test_result:"Test sonucu",
                    question:"Soru",
                    answers:"Yanıtlar",
                    passed:"Geçti",
                    nopassed:"Geçmedi",
                    days_to_passed:"Önceki test sonucu",
                    days_to_next:"Bir sonraki teste sayılı günler kaldı",
                    lesson:"Ders",
                    test:"Ölçek",
                    passing_score:"Kabul puanı",
                    time_to_test:"Test süresi, dakika",
                    minutes:"dakika",
                    alert_title:"Test yükleme hatası",
                    alert_message:"Test silinmiş olmalı. Lütfen yöneticinize başvurun.",
                    alert_cancel:"Anladım",
                    notpublished:"Yayınlanmadı",
                    right:"doğru",
                  },
                  de:{
                    q_title_block:"Block",
                    course_comps: "Kompetenzen",
                    where_look_cert: "Das Zertifikat kann im linken Menü unter „Meine Zertifikate“ abgerufen werden",
                    where_look_rezult: "Die Ergebnisse können im linken Menü ‚Meine Tests‘ oder ‚Meine Kurse‘, eingesehen werden, wenn der Test im Rahmen des Kurses bestanden wird, sowie in Berichten",
                    feedback_placeholder:"Feedback",
                    not_published_yet: "Der Test ist derzeit nicht veröffentlicht",
                    no_more_attempts: "Max. Versuche verwendet",
                    max_attempts:"Maximale Versuche",
                    max_attempts_plc:"0-kein Limit",
                    module_complete: "Komplettes Modul",
                    course_navigation:"Kursnavigation",
                    all_req_title: "Achtung!",
                    all_req_message:"Diese Frage erfordert eine obligatorische Antwort.",
                    all_req_cancel:"Verstanden",

                    comment_saved: "Kommentar gespeichert",
                    direct_link:"Directe link",
                    textDone_copy:"Kopiert",
                    title_stop_test: "Test stoppen?",
                    message_stop_test:"Achtung! Der Test wird beendet und die Testergebnisse werden gespeichert.",
                    get_next:"Zum nächsten Modul wechseln",
                    complete_the_course:"Der Kurs wurde bestanden. Den Kurs abschließen.",
                    sub:"Fügen Sie die richtigen Wörter, Zahlen, Werte ein",
                    pc_right: "Korrekte Antworten",
                    no_passed_yet:"die Prüfung ist noch nicht bestanden",
                    test_passed:"Test bestanden",
                    testAllreadyPassed:" Sie haben diesen Test bereits bestanden. Das Ergebnis kann in Ihrem persönlichen Konto eingesehen werden. Wiederholtes Bestehen ersetzt die Ergebnisse Ihrer Tests.",
                    alertForQuestion: "Falsche Antwort",
                    test_start:"habe angefangen die Prüfung zu bestehen",
                    test_end: "fertig mit der Prüfung",
                    right_quest: "korrekte Antworten",
                    question_weight: "Fragegewicht",
                    responsible_assigned:"Ihnen wird die Verantwortung für die Frage übertragen",
                    responsible_assigned2: "Um die Antwort zu überprüfen, öffnen Sie bitte die Registerkarte „Meine Schecks“ in Ihrem persönlichen Konto, überprüfen Sie die Richtigkeit der Antwort und notieren Sie die Bewertung.",
                    opn: "Schreibe deine Antwort",
                    test_result_opn: "Der Test enthält offene Fragen.<br> Das endgültige Ergebnis des Bestehens des Tests wird nach der Überprüfung bekannt.",
                    seq:"Reihenfolge. Klicken Sie auf das Listenelement und halten Sie es gedrückt, um es zu ziehen",
                    eqw:"Passend. Klicken Sie auf das Listenelement und halten Sie es gedrückt, um es zu ziehen.",
                    title:"Prüfen",
                    goback:"Zurück",
                    loading:"Wird geladen",
                    remove:"Entfernen",
                    thematics:"Gegenstand",
                    description:"Beschreibung",
                    level:"Schwierigkeitsgrad",
                    start:"Test starten",
                    continue:"Weiter testen",
                    stop:"Test beenden",
                    time_left:"Übrige Zeit",
                    prev:'< Vorherige',
                    next:"Nächste >",
                    selectOne:"Wähle eine richtige Antwort aus",
                    selectAny:"Виберіть правильні відповіді",
                    time_is_up:"Keine Zeit mehr",
                    test_result:"Testergebnis",
                    question:"Frage",
                    answers:"Antworten",
                    passed:"Bestanden",
                    nopassed:"Nicht bestanden",
                    days_to_passed:"Vorheriges Testergebnis",
                    days_to_next:"Noch Tage bis zum nächsten Test",
                    lesson:"Lektion",
                    test:"Prüfen",
                    passing_score:"Zulassungsergebnis",
                    time_to_test:"Testzeit, Minuten",
                    minutes:"Minuten",
                    alert_title:"Fehler beim Laden des Tests",
                    alert_message:"Test muss gelöscht worden sein. Bitte wenden Sie sich an Ihren Administrator.",
                    alert_cancel:"Ich habs",
                    notpublished:"Nicht veröffentlicht",
                    right:"Korrekt",
                  },
                  pl:{
                    q_title_block:"blok",
                    course_comps: "Kompetencje",
                    where_look_cert: "Certyfikat można uzyskać w lewym menu w zakładce 'Moje certyfikaty'",
                    where_look_rezult: "Wyniki można zobaczyć w lewym menu 'Moje testy' lub 'Moje kursy', jeśli test został zaliczony w ramach kursu, a także w Raportach",
                    feedback_placeholder:"feedback",
                    not_published_yet: "Test nie jest obecnie opublikowany",
                    no_more_attempts: "Użyto maksymalnej liczby prób",
                    max_attempts:"Maksymalna liczba prób",
                    max_attempts_plc:"0-bez limitu",
                    module_complete: "Kompletny moduł",
                    Course_navigation:"Nawigacja po kursie",
                    all_req_title: "Uwaga!",
                    all_req_message:"To pytanie wymaga obowiązkowej odpowiedzi.",
                    all_req_cancel:"Rozumiem",

                    comment_saved: "Komentarz zapisany",
                    direct_link:"Bezpośredni link",
                    textDone_copy:"Skopiowano",
                    title_stop_test: "Zatrzymać test?",
                    message_stop_test:"Uwaga! Test zostanie zakończony, a wyniki zostaną zapisane.",
                    get_next: "przejdź do następnego modułu",
                    complete_the_course: "kurs został ukończony. Ukończ kurs.",
                    sub: "Wstaw poprawne słowa, cyfry, znaczenia",
                    pc_right: "Poprawne odpowiedzi",
                    no_passed_yet:"test nie został jeszcze zdany",
                    test_passed:"test zaliczony",
                    testAllreadyPassed:"Zdałeś już ten test. Wynik można zobaczyć na swoim koncie osobistym. Powtórne zdanie zastąpi wyniki twoich testów.",
                    alertForQuestion: "Niepoprawna odpowiedź",
                    test_start:"zaczął zdawać test",
                    test_end: "skończył zdać test",
                    right_quest: "poprawne odpowiedzi",
                    question_weight: "Waga pytania",
                    responsible_assigned:"Jesteś odpowiedzialny za pytanie",
                    responsible_assigned2: "Aby sprawdzić odpowiedź, otwórz zakładkę „Moje czeki” na swoim koncie osobistym, sprawdź poprawność odpowiedzi i zapisz ocenę.",
                    opn: "Napisz swoją odpowiedź",
                    test_result_opn: "Test zawiera pytania otwarte.<br>Ostateczny wynik zdania testu poznamy po ich weryfikacji.",
                    seq:"Sekwencja. Kliknij element listy i przytrzymaj, aby przeciągnąć.",
                    eqw:"Dopasowanie. Kliknij element listy i przytrzymaj, aby przeciągnąć.",
                    title:"Test",
                    goback:"Plecy",
                    loading:"Ładowanie",
                    remove:"Usunąć",
                    thematics:"Podmiot",
                    description:"Opis",
                    level:"Poziom trudności",
                    start:"Rozpocznij testowanie",
                    continue:"Kontynuuj testowanie",
                    stop:"Zakończ testowanie",
                    time_left:"Pozostały czas",
                    prev:'< Poprzedni',
                    next:"Następny >",
                    selectOne:"Wybierz jedną poprawną odpowiedź",
                    selectAny:"Wybierz prawidłowe odpowiedzi",
                    time_is_up:"Nie ma już czasu",
                    test_result:"Wynik testu",
                    question:"Pytanie",
                    answers:"Odpowiedzi",
                    passed:"Przeszedł",
                    nopassed:"Nie zdany",
                    days_to_passed:"Wyniki poprzednich testów",
                    days_to_next:"Pozostało dni do kolejnych testów",
                    lesson:"Lekcja",
                    test:"Test",
                    passing_score:"Punktacja wstępu",
                    time_to_test:"Czas testu, minuty",
                    minutes:"minuty",
                    alert_title:"Błąd ładowania testowego",
                    alert_message:"Test musiał zostać usunięty. Skontaktuj się z administratorem.",
                    alert_cancel:"Rozumiem",
                    notpublished:"Nie publikowany",
                    right:"prawidłowy",
                  },
                  pt:{
                    q_title_block: "Bloco",
                    course_comps: "Competências",
                    where_look_cert: "O certificado pode ser obtido no menu esquerdo em 'Meus Certificados'",
                    where_look_rezult: "Os resultados podem ser visualizados no menu esquerdo 'Meus testes' ou 'Meus cursos', se o teste for aprovado como parte do curso, bem como em Relatórios",
                    feedback_placeholder: "comentários",
                    not_published_yet: "O teste não está publicado no momento",
                    no_more_attempts: "Tentativas máximas usadas",
                    max_attempts:"Tentativas Máximas",
                    max_attempts_plc:"0-sem limite",
                    module_complete: "Módulo completo",
                    course_navigation:"Navegação do curso",
                    all_req_title: "Atenção!",
                    all_req_message:"Esta pergunta requer uma resposta obrigatória.",
                    all_req_cancel:"Entendi",

                    comment_saved: "Comentário salvo",
                    direct_link:"Link direto",
                    textDone_copy:"Copiado",
                    title_stop_test: "Parar teste?",
                    message_stop_test:"Atenção! O teste terminará e os resultados do teste serão salvos.",
                    get_next: "ir para o próximo módulo",
                    complete_the_course: "curso concluído. Terminar o curso.",
                    sub: "insira as palavras corretas, números, significados",
                    pc_right: "Respostas corretas",
                    no_passed_yet:"o teste ainda não foi passado",
                    test_passed:"teste passou",
                    testAllreadyPassed:"Você já passou neste teste. O resultado pode ser visualizado em sua conta pessoal. A aprovação repetida substituirá os resultados do seu teste.",
                    alertForQuestion: "Resposta incorreta",
                    test_start:"começou a passar no teste",
                    test_end: "terminou de passar no teste",
                    right_quest: "respostas corretas",
                    question_weight: "Peso da pergunta",
                    responsible_assigned:"Você é designado com a responsabilidade pela questão",
                    responsible_assigned2: "Para verificar a resposta, abra a guia 'Meus cheques' em sua conta pessoal, verifique se a resposta está correta e anote a classificação.",
                    opn: "Escreva sua resposta",
                    test_result_opn: "O teste contém questões abertas.<br>O resultado final da aprovação no teste será conhecido após sua verificação.",
                    seq:"Seqüência. Clique no elemento da lista e segure para arrastar.",
                    eqw:"Coincidindo. Clique no elemento da lista e segure para arrastar.",
                    title:"Teste",
                    goback:"Voltar",
                    loading:"Loading",
                    remove:"Retirar",
                    thematics:"Sujeito",
                    description:"Descrição",
                    level:"Nível de dificuldade",
                    start:"Comece o teste",
                    continue:"Continue testando",
                    stop:"Concluir o teste",
                    time_left:"Tempo restante",
                    prev:'< Anterior',
                    next:"Próximo >",
                    selectOne:"Selecione uma resposta correta",
                    selectAny:"Escolha as respostas corretas",
                    time_is_up:"Não há mais tempo",
                    test_result:"Resultado do teste",
                    question:"Pergunta",
                    answers:"Respostas",
                    passed:"Passado",
                    nopassed:"Não passou",
                    days_to_passed:"Resultado do teste anterior",
                    days_to_next:"Dias restantes para o próximo teste",
                    lesson:"Lição",
                    test:"Teste",
                    passing_score:"Pontuação de admissão",
                    time_to_test:"Tempo de teste, minutos",
                    minutes:"minutos",
                    alert_title:"Erro de carregamento de teste",
                    alert_message:"O teste deve ter sido excluído. Entre em contato com o seu administrador.",
                    alert_cancel:"Entendi",
                    notpublished:"Não publicado",
                    right:"correto",
                  },
                  es:{
                    q_title_block:"Bloque",
                    course_comps: "Competencias",
                    where_look_cert: "El certificado se puede obtener en el menú de la izquierda en 'Mis certificados'",
                    where_look_rezult: "Los resultados se pueden ver en el menú de la izquierda 'Mis pruebas' o 'Mis cursos', si la prueba se aprueba como parte del curso, así como en Informes",
                    feedback_placeholder: "Comentarios",
                    not_published_yet: "La prueba no está publicada actualmente",
                    no_more_attempts: "Máximo de intentos usados",
                    max_attempts:"Máximo de intentos",
                    max_attempts_plc:"0-sin límite",
                    module_complete: "Módulo completo",
                    course_navigation:"Navegación del curso",
                    all_req_title: "¡Atención!",
                    all_req_message:"Esta pregunta requiere una respuesta obligatoria.",
                    all_req_cancel:"Entendido",

                    comment_saved: "Comentario guardado",
                    direct_link:"Enlace directo",
                    textDone_copy:"Copiado",
                    title_stop_test: "¿Detener prueba?",
                    message_stop_test:"¡Atención! La prueba terminará y los resultados de la prueba se guardarán.",
                    get_next: "Ir al siguiente módulo",
                    complete_the_course: "Curso completado. Completar el curso.",
                    sub: "Insertar palabras correctas, números, significados",
                    pc_right: "Respuestas correctas",
                    no_passed_yet:" a prueba aún no ha sido superada",
                    test_passed:"prueba aprobada",
                    testAllreadyPassed:"Ya pasaste esta prueba. El resultado se puede ver en su cuenta personal. Pasar repetidamente reemplazará los resultados de su prueba.",
                    alertForQuestion: "Respuesta incorrecta",
                    test_start:"empezó a pasar la prueba",
                    test_end: "terminó de pasar la prueba",
                    right_quest: "respuestas correctas",
                    question_weight: "Peso de la pregunta",
                    responsible_assigned:"Se le asigna la responsabilidad de la pregunta",
                    responsible_assigned2: "Para verificar la respuesta, abra la pestaña 'Mis cheques' en su cuenta personal, verifique que la respuesta sea correcta y anote la calificación.",
                    opn: "Escribe tu respuesta",
                    test_result_opn: "La prueba contiene preguntas abiertas.<br>El resultado final de pasar la prueba se conocerá tras su verificación.",
                    seq:"Secuencia. Haga clic en el elemento de la lista y manténgalo presionado para arrastrar.",
                    eqw:"Pareo. Haga clic en el elemento de la lista y manténgalo presionado para arrastrar.",
                    title:"Prueba",
                    goback:"Atrás",
                    loading:"Cargando",
                    remove:"Eliminar",
                    thematics:"Sujeto",
                    description:"Descripción",
                    level:"Nivel de dificultad",
                    start:"Empiece a probar",
                    continue:"Continuar probando",
                    stop:"Terminar la prueba",
                    time_left:"Tiempo restante",
                    prev:'< Previo',
                    next:"Próximo >",
                    selectOne:"Seleccione una respuesta correcta",
                    selectAny:"Elige las respuestas correctas",
                    time_is_up:"No queda tiempo",
                    test_result:"Resultado de la prueba",
                    question:"Pregunta",
                    answers:"Respuestas",
                    passed:"Aprobado",
                    nopassed:"No pasó",
                    days_to_passed:"Resultado de la prueba anterior",
                    days_to_next:"Días que quedan para la próxima prueba",
                    lesson:"Lección",
                    test:"Prueba",
                    passing_score:"Puntuación de admisión",
                    time_to_test:"Tiempo de prueba, minutos",
                    minutes:"minutos",
                    alert_title:"Error de carga de prueba",
                    alert_message:"La prueba debe haber sido eliminada. Comuníquese con su administrador.",
                    alert_cancel:"Entiendo",
                    notpublished:"No publicado",
                    right:"correcto",
                  },
                  vn:{
                    q_title_block: "Khối",
                    course_comps: "Năng lực",
                    where_look_cert: "Chứng chỉ có thể lấy được ở menu bên trái trong 'Chứng chỉ của tôi'",
                    where_look_rezult: "Có thể xem kết quả trong menu bên trái 'Bài kiểm tra của tôi' hoặc 'Khóa học của tôi', nếu bài kiểm tra được vượt qua như một phần của khóa học, cũng như trong Báo cáo",
                    feedback_placeholder: "Phản Hồi",
                    not_published_yet: "Bài kiểm tra hiện chưa được xuất bản",
                    no_more_attempts: "Số lần thử tối đa đã sử dụng",
                    max_attempts:"Số lần thử tối đa",
                    max_attempts_plc:"0-không giới hạn",
                    module_complete: "Hoàn thành mô-đun",
                    course_navigation:"Điều hướng khóa học",
                    all_req_title: "Chú ý!",
                    all_req_message:"Câu hỏi này yêu cầu câu trả lời bắt buộc.",
                    all_req_cancel:"Hiểu rồi",

                    comment_saved: "Đã lưu bình luận",
                    direct_link:"Liên kết trực tiếp",
                    textDone_copy:"Đã sao chép",
                    title_stop_test: "Dừng Kiểm tra?",
                    message_stop_test:"Chú ý! Bài kiểm tra sẽ kết thúc và kết quả kiểm tra sẽ được lưu.",
                    get_next: "chuyển đến mô-đun tiếp theo",
                    complete_the_course: "khóa học đã được hoàn thành. Hoàn thành khóa học.",
                    sub:"Chèn lời chính xác số giá trị",
                    pc_right: "Câu trả lời đúng",
                    no_passed_yet:"bài kiểm tra vẫn chưa được thông qua",
                    test_passed:"kiểm tra vượt qua",
                    testAllreadyPassed:"Bạn đã vượt qua bài kiểm tra này. Kết quả có thể được xem trong tài khoản cá nhân của bạn. Việc vượt qua nhiều lần sẽ thay thế kết quả kiểm tra của bạn.",
                    alertForQuestion: "Câu trả lời chưa chính xác",
                    test_start:"bắt đầu vượt qua bài kiểm tra",
                    test_end: "đã hoàn thành bài kiểm tra",
                    right_quest: "câu trả lời đúng",
                    question_weight: "Trọng lượng câu hỏi",
                    responsible_assigned:"Bạn được giao trách nhiệm cho câu hỏi",
                    responsible_assigned2: "Để kiểm tra phản hồi, vui lòng mở tab 'Kiểm tra của tôi' trong Tài khoản Cá nhân của bạn, kiểm tra tính đúng đắn của phản hồi và ghi lại xếp hạng.",
                    opn: "Viết câu trả lời của bạn",
                    test_result_opn: "Bài kiểm tra chứa các câu hỏi mở. <br> Kết quả cuối cùng của việc vượt qua bài kiểm tra sẽ được biết sau khi xác minh của họ.",
                    seq:"Sự nối tiếp. Nhấp vào phần tử danh sách và giữ để kéo.",
                    eqw:"Sự phù hợp. Nhấp vào phần tử danh sách và giữ để kéo.",
                    title:"Thử nghiệm",
                    goback:"Mặt sau",
                    loading:"Đang tải",
                    remove:"Di dời",
                    thematics:"Chủ thể",
                    description:"Sự miêu tả",
                    level:"Cấp độ khó",
                    start:"Bắt đầu thử nghiệm",
                    continue:"Tiếp tục thử nghiệm",
                    stop:"Kết thúc thử nghiệm",
                    time_left:"Thời gian còn lại",
                    prev:'< Trước',
                    next:"Kế tiếp >",
                    selectOne:"Chọn một câu trả lời đúng",
                    selectAny:"Chọn những đáp án đúng",
                    time_is_up:"Không có thời gian còn lại",
                    test_result:"Kết quả kiểm tra",
                    question:"Câu hỏi",
                    answers:"Câu trả lời",
                    passed:"Đi qua",
                    nopassed:"Không được thông qua",
                    days_to_passed:"Kết quả thử nghiệm trước đó",
                    days_to_next:"Số ngày còn lại cho thử nghiệm tiếp theo",
                    lesson:"Bài học",
                    test:"Thử nghiệm",
                    passing_score:"Điểm xét tuyển",
                    time_to_test:"Thời gian kiểm tra, phút",
                    minutes:"phút",
                    alert_title:"Kiểm tra lỗi tải",
                    alert_message:"Kiểm tra phải được xóa. Vui lòng liên hệ với quản trị viên của bạn.",
                    alert_cancel:"Hiểu rồi",
                    notpublished:"Chưa xuất bản",
                    right:"Chính xác",
                  },

                },
            }
        },
        updated: function () {
            //console.log(':: TestView updated ',this.test.isLoaded);
            // if(!this.test.isLoaded) {
            //     this.initTest();
            // }
        },
        mounted: function(){
            // console.log(':: TestView mounted user_current=', this.user_current);
          let $this=this;
          $this.parent.uCoursesTestsGet_Cache='';
          $this.parent.checkPulse('test').then(res=>{
            this.initTest();
          }, err=>{
            this.$emit('goback', 'studentProfile');
          });

        },
        watch:{
            // lang: function(){
            //     //console.log(':: lang',lang);
            // },
        },
        computed:{
          availPeriods(){
            let ret = true;
            if(this.test.PROPERTY_VALUES.flags.avail_period && this.test.PROPERTY_VALUES.flags.avail_days && this.test.PROPERTY_VALUES.flags.avail_days.length>0){
              let today=dayjs().format('YYYY-MM-DD');
              ret = false;
              for(let item of this.test.PROPERTY_VALUES.flags.avail_days){
                if(today >= item.from && today <= item.to){
                  ret = true;
                  break;
                }
              }
              if(!ret){
                let $this=this;
                console.error(':: Тест вне сроков доступности')
                $this.parent.notify_title = this.parent.message[this.lang].bx24_alert;
                $this.parent.notify_text = this.parent.message[this.lang].module_out_from_dates;
                $this.parent.notify_text += ": " + this.test.PROPERTY_VALUES.flags.avail_days.map(el=>this.parent.customFormatter(el.from) + ' - ' + this.parent.customFormatter(el.to)).join(', ');
                $this.parent.notify_warning = true;
                $this.parent.notify = true;
              }
            }
            return ret;
          },
          isMoreTestAttempts(){
            return  !this.test.PROPERTY_VALUES.flags.att || this.countTestAttempts < parseInt(this.test.PROPERTY_VALUES.flags.att);
          },
          isLastNav(){
            let ret=false;
            let len = Object.keys(this.nav).length;
            for(let i=0; i<len-1; i++){
              if(this.nav[i].id==this.testItem.ID && i<len-1){
                ret=true;
                break;
              }
            }
            return ret;
          },
          isNavFinished(){
            // console.log('::isNavFinished nav=',this.nav);
            let ret=true;
            if(this.nav){
              let nav = this.nav;
              if(this.testItem.tree) {
                nav = this.parent.flattenArray(nav);
              }
              for(let i in nav) {
                let item = nav[i];
                // console.log('::isNavFinished item=',item.type, item.id, this.testItem.ID, item);

                if (item.type!=='sep' && item.stage !== 'Y' && this.testItem.alw_next == 2) {
                  ret = false;
                  break;
                }else if(item.id == this.testItem.ID){
                  //если это последний модуль и не стоит признак "не переходить дальше если этот не пройден"
                  // то можно здесь завершать курс
                  ret = true;
                }else if(item.type!=='sep' && item.stage !== 'Y') {
                  ret = false;
                }
              }
            }else{
              ret = false;
            }
            return ret;
          },

          showSubstitution() {
            // console.log(':1:showSubstitution this.currentTest=',this.currentTest)
            let item = this.parent.showBase64Text(this.currentTest.questions[this.currentTest.currentQuest].text);
            // console.log('::showSubstitution quest=',item)
            let questNumber = this.currentTest.questions[this.currentTest.currentQuest].value;
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.currentTest.answers2[questNumber]={};
            let arr = item.split('##');
            // console.log('::arr= ', arr)
            let ret=[];
            let nn=0;
            for(let i in arr){
              if(i%2==0){
                ret.push(arr[i]);
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.currentTest.answers2[questNumber][nn]='';
                nn++;
              }
            }
            // console.log('::showSubstitution ret=',ret)
            return ret;
          },
            isQuestions(){
                return this.test && this.test.PROPERTY_VALUES && this.test.PROPERTY_VALUES.questions && Object.keys(this.test.PROPERTY_VALUES.questions).length > 0;
            },
            questToDisplay(){
              if(this.test.PROPERTY_VALUES.flags.block){
                return this.test.PROPERTY_VALUES.todisplay;
              }
              let to = parseInt(this.test.PROPERTY_VALUES.todisplay);
              if(to === 0) {
                  to = Object.keys(this.test.PROPERTY_VALUES.questions).length;
              }
              return to;
            },
            isMulty: function(){
              //НЕ ИСПОЛЬЗУЕТСЯ
              //проверяет вопрос на множественный выбор или нет
                let arr = this.currentTest.answers[this.currentTest.questions[this.currentTest.currentQuest].value];
                let nTrue=0;
                for(let i in arr){
                    let item = arr[i];
                    if(item.right && (item.right === true || item.right === 'true' || item.right.toLowerCase() == this.message[this.lang].right )){
                        nTrue ++;
                    }
                }
                return nTrue > 1;
            },

        },
        methods: {
          showBlock(block_id){
            let block = this.test.PROPERTY_VALUES.blocks.find(el=>{return el.id == block_id});
            return block ? block.name : block_id;
          },
          saveImages(list){
            // console.log(':***:saveImages list=',list)
            if(this.parent.isJSON(list)){
              list = JSON.parse(list);
            }
            this.imgFiles = list;
            let hash = this.parent.myEx(this.currentTest.questions[this.currentTest.currentQuest].text)

            // let listFiles = list.filter(el=>{return el.curquest==hash}).map(el=>{return {I:el.I, N:el.N, H:el.H}});
            // console.log(':***:saveImages hash,list=',hash,this.imgFiles)
            // console.log(':***:saveImages filter=',list.filter(el=>{return el.curquest==hash}))

            let listFiles=[];
            for(let i=0; i<Object.keys(list).length; i++){
              let item = list[i];
              // console.log(':***:saveImages item=',item.curquest==hash,item)
              if(item.curquest==hash){
                listFiles.push(item);
              }
            }

            console.log(':***:saveImages listFiles=',listFiles)
            this.opnFiles[`f_${hash}`] = listFiles
            console.log('::saveImages currentTest=', this.opnFiles);
          },
          saveComment(arg){
            let message = this.message[this.lang].comment_saved +'. '+this.message[this.lang].title+': '+
              "["+this.test.ID+"] "+ this.test.NAME +'. "'+ arg.comment+'". '+arg.user.name+'©';

            this.parent.isNoteSet(this.parent.moderators.map(el => {return el.id}), "test").then(checks=>{
              if (Object.keys(checks).length > 0) {
                let arBatch = {};
                for (let uid of checks) {
                  // arBatch['note_' + uid] = ['im.notify', {to: uid, message: message, type: 'SYSTEM'}];
                  // Уведомление в ЛК
                  this.parent.notifyLK(uid, message, this.parent.user_current);
                }
                // this.parent.callBatch(arBatch);
              }
            });

          },
          copy(id) {
            this.$refs['clone'+id].focus();
            document.execCommand('copy');
          },
          // getDirectLink(id){
          //   const link = `${this.parent.appURL}?test=${id}`;
          //   console.log('::getDirectLink ',link);
          //   navigator.clipboard.writeText(link);
          //   this.textDone = this.message[this.lang].textDone_copy;
          //   this.showDone = true;
          // },
          getNextNav(){
            let flt = this.nav.filter(el=>{return el.type!=='sep'});
            const len = flt.length;
            // console.log('::getNextNav len,flt=', this.testItem.ID, len, flt)
            for(let i=0; i<len-1; i++){
              if(flt[i].id==this.testItem.ID){
                this.viewItem(flt[i+1]);
                break;
              }
            }
          },
          completeTheCourse(){
            if(this.testItem.tree){
              this.$emit('completeTheCourse', true);
            }else {
              this.goback.completed = 'Y';
              this.$emit('goback', this.goback);
            }
          },
          seq_Log(arg){
              // console.log('::seq_Log ',arg);
            if(this.currentTest.show_results == 3 && this.currentTest.no_prev!=='Y') {
              this.saveCurrentTest('', '' + this.currentTest.currentQuest, 0);
            }else{
              this.saveCurrentTest();
            }
          },
          showTimeLeft(){
              let ret='';
              if(this.currentTest) {
                  let mm = String(Math.floor(this.currentTest.time_left / 60));
                  let ss = String(Math.floor(this.currentTest.time_left - Math.floor(this.currentTest.time_left / 60) * 60));
                  mm = mm.length > 1 ? mm : '0' + mm;
                  ss = ss.length > 1 ? ss : '0' + ss;

                  ret = mm + ':' + ss;
              }
              return ret;
          },
          showItem(item){
            //console.log('::showItem ',item.value, item.data?'yes data':'no data');
            return " ";
          },
          viewItem(element, force){
            // console.log('::viewItem element=' + element);
            if(force){
              element.stage = 'View';
            }
            if(element.stage !== 'D' || this.testItem.alw_next<2) {
                let meth = element.type + 'ViewById';
                //console.log('::viewItem id=' + element.id + ' meth=' + meth + ' nav=', this.nav);
                this.$emit(meth,
                  {id: element.id,
                    nav: this.nav,
                    course_id: this.course_id,
                    save_id:this.userCourse_id,
                    sched_id:this.testItem.sched_id,
                    program_chedule_id:this.testItem.program_chedule_id,
                    alw_next:this.testItem.alw_next,
                    time:this.testItem.time,
                    start:this.testItem.start,
                    or_course:this.testItem.for_course
                  });
            }
          },
          loadFile(i,j){
             let answer = this.test.PROPERTY_VALUES.answers[i][j];
                // //console.log('::loadFile for ',answer);
                var $this = this;
                let params = {
                  'ENTITY': 'uni_files',
                  'SORT': {},
                  'FILTER': {
                    'ID': answer.file
                  }
                };
                this.isFilesLoading=true;
                // BX24.callMethod('entity.item.get', {
                //     'ENTITY': 'uni_files',
                //     'SORT': {},
                //     'FILTER': {
                //         'ID': answer.file
                //     }
                //
                // }, function(res){
                //     // //console.log(res);
                //     $this.isFilesLoading=false;
                //     if(res.answer.error){
                //         // console.error(':: loadFile ',res.answer.error_description);
                //         $this.msg = res.answer.error_description;
                //     }else{
            let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });//.then(params=> {
            $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
              $this.isFilesLoading=false;
              if(res.result && res.result[0]){
                            var item=res.result[0];
                            // answer.data = item.DETAIL_TEXT;
                            $this.test.PROPERTY_VALUES.answers[i][j].data = item.DETAIL_TEXT;
                            // //console.log(':: loadFile ', answer)
                        }
                    // }
                });
                return true;
            },
          loadFileQuestion(i){
            let $this = this;
            let question = this.test.PROPERTY_VALUES.questions[i];
            //console.log('::loadFile for ',answer);
            if(!question.file || isNaN(question.file)){
              return false;
            }else {
              let $this = this;
              let params = {
                'ENTITY': 'uni_files',
                'SORT': {},
                'FILTER': {
                  'ID': question.file
                }
              };
              this.isFilesLoading=true;
              // BX24.callMethod('entity.item.get', {
              //   'ENTITY': 'uni_files',
              //   'SORT': {},
              //   'FILTER': {
              //     'ID': question.file
              //   }
              // }, function (res) {
              //   $this.isFilesLoading=false;
              //   // //console.log(res);
              //   if (res.answer.error) {
              //     console.error(':: loadFile ', res.answer.error_description);
              //   } else {
              let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });//.then(params=> {
              $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
                $this.isFilesLoading=false;
                if (res.result && res.result[0]) {
                    var item = res.result[0];
                    $this.test.PROPERTY_VALUES.questions[i].data = item.DETAIL_TEXT;
                    //console.log(':: loadFile ok', item)
                  }
                // }
              });
              return true;
            }
          },
          isAnswersChecked(){
            let ret = true;
            let all_req = this.test.PROPERTY_VALUES.flags.all_req;
            if(all_req) {
              let question = this.currentTest.questions[this.currentTest.currentQuest];
              if (question.type === 'qa') {
                // console.log(':***: isAnswersChecked, question=', question)
                // console.log(':***: isAnswersChecked, currentTest.answers=', this.currentTest.answers)
                ret = false;
                for (let i in this.currentTest.answers[question.value]) {
                  let item = this.currentTest.answers[question.value][i]
                  if (item.checked) {
                    ret = true;
                    break;
                  }
                }
                if (!ret) {
                  console.error('::isAnswersChecked not checked answers')
                } else {
                  console.log('::isAnswersChecked checked answers ok')
                }
              }
            }
            // else{
            //   console.log(':: no all_req')
            // }
            return ret;
          },
          checkTogle(index){
            // //console.log(':start: checkTogle ', index);
            // this.selectedCursor='wait';
            // if(this.isMulty){

              //Всегда даем возможность отметить несколько ответов
                this.currentTest.answers[this.currentTest.questions[this.currentTest.currentQuest].value][index].checked =
                    !this.currentTest.answers[this.currentTest.questions[this.currentTest.currentQuest].value][index].checked;

            // }else{
            //     for(let i in this.currentTest.answers[this.currentTest.questions[this.currentTest.currentQuest].value]){
            //         if(i != index) {
            //             this.currentTest.answers[this.currentTest.questions[this.currentTest.currentQuest].value][i].checked = false;
            //         }
            //     }
            //     this.currentTest.answers[this.currentTest.questions[this.currentTest.currentQuest].value][index].checked =
            //         !this.currentTest.answers[this.currentTest.questions[this.currentTest.currentQuest].value][index].checked;
            // }
            // this.selectedCursor='pointer';
            if(this.currentTest.show_results == 3 && this.currentTest.no_prev!=='Y') {
              this.saveCurrentTest('', '' + this.currentTest.currentQuest, 0);
            }else{
              this.saveCurrentTest();
            }
          },
          getNext(){
            this.allRight = 'noset';
            // console.log('::getNext questToDisplay',this.questToDisplay, this.currentTest.currentQuest);
            if(this.isAnswersChecked()) {
              if (this.currentTest.currentQuest < this.questToDisplay) {
                if (this.currentTest.show_results == 3) {
                  this.saveCurrentTest('', '' + this.currentTest.currentQuest, 1);
                }else {
                  this.currentTest.currentQuest++;
                  this.saveCurrentTest();
                }
                if (this.currentTest.questions[this.currentTest.currentQuest].file && !this.currentTest.questions[this.currentTest.currentQuest].data) {
                  console.log('::getNext no data loaded!');
                  this.loadFileQuestion(this.currentTest.currentQuest);
                }
                // console.log('::getNext ',this.currentTest.currentQuest, this.currentTest.questions[this.currentTest.currentQuest]);
              }
            }else{
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify_text = this.message[this.lang].all_req_message;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify_title = this.message[this.lang].all_req_title;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify_ok = this.message[this.lang].all_req_cancel;
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.notify=true;
            }
            this.showLoadImages = false;
            this.$nextTick(() => {
              this.showLoadImages = true;
            });
          },
          getPrev(){
            // //console.log('::getPrev ', this.currentTest.currentQuest, this.currentTest);
            this.allRight = 'noset';
            if(this.currentTest.currentQuest > 0) {
              this.currentTest.currentQuest--;
              if(this.currentTest.questions[this.currentTest.currentQuest].file && !this.currentTest.questions[this.currentTest.currentQuest].data){
                // console.log('::getPrev no data loaded!');
                this.loadFileQuestion(this.currentTest.currentQuest);
              }
              // console.log('::getPrev ', this.currentTest.questions[this.currentTest.currentQuest]);
            }

            this.showLoadImages = false;
            this.$nextTick(() => {
              this.showLoadImages = true;
            });
            this.saveCurrentTest();
          },
          setStage(stage, passed){
            // console.log('::setStage stage=',stage,this.test.ID, this.nav)
            if(this.testItem.tree){
              //Навигация в виде дерева
              // TODO Если в дереве повторяется тест или урок, то здесь будет находиться только первый
              let currentItem = this.parent.getInTreeById(this.nav, this.test.ID);
              // console.log('::setStage currentItem=',currentItem);
              if(currentItem){
                currentItem.stage = stage;
              }
              if(stage === 'Y' || this.testItem.alw_next == 1) {
                currentItem.test_passed=passed;
                let flatten = this.parent.flattenArray(this.nav);
                // console.log('::setStage flatten=',flatten);
                let nextItem = this.parent.findNextElement(flatten, this.test.ID);
                // console.log('::setStage nextItem=',nextItem);
                if (nextItem) {
                  nextItem.stage = stage === 'D' ? '' : nextItem.stage;
                  this.parent.updateInTreeById(this.nav, nextItem.id, nextItem);
                }
              }
            }
            else if(this.nav) {
              let $this=this;
              for (let i in this.nav) {
                let item = this.nav[i];
                if (item.id == this.testItem.ID) {
                    this.nav[i].stage = stage; //текущий тест
                    let inext = parseInt(i)+1; //следующий модуль
                    if(stage === 'Y' && Object.keys(this.nav).length > inext){
                      // console.log(':: inext=',inext, this.nav[inext].stage);
                      this.nav[inext].stage = this.nav[inext].stage === 'D'?'':this.nav[inext].stage;
                    }
                    break;
                }
              }
            }
          },
          initTest(){
            // console.log(':***: initTest this.back=',this.back, ' testItem=',this.testItem);
              const $this=this;
              if(this.testItem.nav){
                  this.nav = this.testItem.nav;
                  this.course_id = parseInt(this.testItem.course_id);
                  this.userCourse_id = this.testItem.save_id;
                  if(this.testItem.program_chedule_id){
                    this.goback = {scr:'courseView', id:this.course_id, program_chedule_id:this.testItem.program_chedule_id, alw_next:this.testItem.alw_next};
                  }else{
                    // eslint-disable-next-line vue/no-mutating-props
                    this.goback =  {scr:'courseView', id:this.course_id, sched_id:this.testItem.sched_id, alw_next:this.testItem.alw_next};//'courseView:'+this.course_id;
                  }
              }else{
                if(this.testItem.program_chedule_id){
                    this.goback = {scr:'studentProfile'};
                  }else{
                  // eslint-disable-next-line vue/no-mutating-props
                    this.goback = this.back?this.back:{scr:'testsList'} //'testsList';
                  }
              }

              // console.log(':***: initTest this.goback=',this.goback, ' testItem=',this.testItem);
              //
              this.getTestById(this.testItem.ID).then((tst) => {
                $this.test = tst;
                // $this.test.isLoaded = true;
                $this.test.sched_id = this.testItem.sched_id;
                $this.test.program_chedule_id = this.testItem.program_chedule_id;

                if($this.checkTestIsPublished()){
                  $this.checkUserTest();

                  // console.log(':1: Test loaded=',  $this.test);

                // console.log(':: Test loaded ID=',  $this.test.ID, $this.test);
                // if($this.test.ACTIVE === 'Y' || $this.parent.isModer || $this.parent.isAdmin) {

                  // $this.test.PROPERTY_VALUES.answers = JSON.parse($this.test.PROPERTY_VALUES.answers);
                  // console.log(':: Test loaded questions=',  $this.test.PROPERTY_VALUES.questions);
                  // console.log(':: Test loaded answers=',  $this.test.PROPERTY_VALUES.answers);
                  $this.test.PROPERTY_VALUES.flags = $this.test.PROPERTY_VALUES.flags?JSON.parse($this.test.PROPERTY_VALUES.flags):{comments_available:false, att:0 };
                  $this.test.PROPERTY_VALUES.flags.att = $this.test.PROPERTY_VALUES.flags.att?parseInt($this.test.PROPERTY_VALUES.flags.att):0;
                  $this.test.PROPERTY_VALUES.todisplay = $this.test.PROPERTY_VALUES.todisplay?parseInt($this.test.PROPERTY_VALUES.todisplay):0;
                  $this.test.PROPERTY_VALUES.todisplay = isNaN($this.test.PROPERTY_VALUES.todisplay)?0:$this.test.PROPERTY_VALUES.todisplay;

                  if($this.test.PROPERTY_VALUES.flags.comp){
                    $this.loading_comps = true;
                    $this.parent.competenceGetList('competence', true).then(res=> {
                      $this.competence = [];
                      for(let comp_id of $this.test.PROPERTY_VALUES.flags.comp) {
                        let comp=res.find(el => el.id == comp_id);
                        if(comp) {
                          $this.competence.push(comp);
                        }
                      }
                      $this.loading_comps = false;
                    });
                  }
                  const DETAIL_TEXT = $this.parent.showBase64Text($this.test.DETAIL_TEXT);
                  // console.log(':1: DETAIL_TEXT=',  DETAIL_TEXT);
                  if($this.parent.isJSON(DETAIL_TEXT)){
                    const tmp=JSON.parse(DETAIL_TEXT);
                    $this.test.PROPERTY_VALUES.questions = tmp.questions;
                    $this.test.PROPERTY_VALUES.answers = tmp.answers ? tmp.answers : {};
                    // console.log(':2: $this.test=',  $this.test);

                  }else {
                    $this.test.PROPERTY_VALUES.questions = JSON.parse($this.test.PROPERTY_VALUES.questions);
                    if (this.parent.isJSON($this.test.PROPERTY_VALUES.answers)) {
                      $this.test.PROPERTY_VALUES.answers = JSON.parse($this.test.PROPERTY_VALUES.answers);
                    } else {
                      $this.test.PROPERTY_VALUES.answers = {};
                    }
                  }

                  $this.test.PROPERTY_VALUES.blocks = $this.parent.isJSON($this.test.PROPERTY_VALUES.blocks) ? JSON.parse($this.test.PROPERTY_VALUES.blocks) : false;

                  // console.log(':1: Test loaded answers=',  $this.test.PROPERTY_VALUES.answers);

                  // if(!$this.test.PROPERTY_VALUES.answers){
                  //   $this.test.PROPERTY_VALUES.answers = {};
                  //   // console.log(':2: Test loaded answers=',  $this.test.PROPERTY_VALUES.answers);
                  // }

                  $this.test.PROPERTY_VALUES.score = parseInt($this.test.PROPERTY_VALUES.score);

                  let sumWeight=0; //сумма весов вопросов, если веса заданы
                  for(let k in $this.test.PROPERTY_VALUES.questions){
                    let item=$this.test.PROPERTY_VALUES.questions[k];
                    // console.log('::item=',  item);
                    $this.loadFileQuestion(k);
                    if(item && item.weight){
                      sumWeight += parseInt(item.weight);
                    }
                    if(item.type==='opn'){
                      $this.test.PROPERTY_VALUES.answers[parseInt(item.value)]='';
                    }
                    //Если сумма весов == 100, значит веса заданы и заданы корректно
                    if(sumWeight == 100){
                      $this.test.isWeight=true; //веса заданы
                      $this.test.PROPERTY_VALUES.todisplay=0; //устанавливаем к показу все вопросы
                    }
                  }
                  // console.log('::-- test.isWeight=',  $this.test.isWeight);

                  $this.getDaysToTestValid();

                  //Покажем баллы
                  let for_course=$this.testItem.for_course;
                  if(!for_course || for_course && (!for_course.active || for_course.active && for_course.dop === 'Y')){
                    let value = $this.test.PROPERTY_VALUES.flags.points?parseInt($this.test.PROPERTY_VALUES.flags.points):0;
                    //в курсе заданы условия что за уроки нужно начислять дополнительно
                    // console.log(':: test=', $this.test)
                    // console.log(':: testItem=', $this.testItem)
                    const test_type = $this.test.PROPERTY_VALUES.type;
                    let type = test_type === 'total' || test_type === 'final' ? 'I':'T';
                    $this.pointsShow = $this.parent.showPoints(type, $this.test.ID, value);
                  }
                }
                setTimeout(() => {
                  $this.test.isLoaded = true;                
                }, 1000);
                // else{
                //   //тест не активен, пройти его могут только модеры
                //   $this.$emit('goback', 'testsList');
                //   $this.alertNoTest();
                // }
              }, error => {
                  $this.$emit('goback', 'testsList');
                  if($this.test && typeof $this.test == 'object') {
                    $this.test.isLoaded = true;
                  }
                  console.error(error);
                  $this.alertNoTest();
              });
          },
          alertNoTest(){
            // eslint-disable-next-line vue/no-mutating-props
            this.parent.notify_text = this.message[this.lang].alert_message;
            // eslint-disable-next-line vue/no-mutating-props
            this.parent.notify_title = this.message[this.lang].alert_title;
            // eslint-disable-next-line vue/no-mutating-props
            this.parent.notify_ok = this.message[this.lang].alert_cancel;
            // eslint-disable-next-line vue/no-mutating-props
            this.parent.notify=true;

            // this.parent.modal_title=this.message[this.lang].alert_title;
              // this.parent.modal_message=this.message[this.lang].alert_message;
              // this.parent.modal_cancel=this.message[this.lang].alert_cancel;
              // this.parent.okModal =  null;
              // BX24.scrollParentWindow (0);
              //
              // this.parent.showModal();
          },

          getDaysToTestValid(isFinish){
            // console.log('::getDaysToTestValid test.ID=',isFinish,this.test);
            this.parent.userOption('get','test_'+this.test.ID).then(savedTest=>{
              // console.log(':1:getDaysToTestValid savedTest=', savedTest);
              this.daysToTestValid = 0;
              this.daysToTestPassed = null;
              if(savedTest){
                // let currentTime = Math.floor(Date.now() / 1000);
                this.parent.getServerTimeStamp().then(currentTime=>{
                  // console.log('::currentTime=',currentTime)
                  savedTest = JSON.parse(savedTest);
                  // console.log(':2:getDaysToTestValid savedTest=', savedTest);
                  this.daysToTestValid = Math.max(0, parseInt(this.test.PROPERTY_VALUES.interval) - Math.floor((currentTime - savedTest.startTime)/60/60/24) ); //days
                  this.daysToTestValid = isNaN(this.daysToTestValid) ? 0 : this.daysToTestValid;
                  this.daysToTestPassed = savedTest.passed?savedTest.passed:null;
                  this.daysToTestEnded = true;// !!savedTest.isEnded || savedTest.passed || savedTest.att ?true:false;
                  if(this.test.PROPERTY_VALUES.flags.att) {
                    this.countTestAttempts = savedTest.att ? parseInt(savedTest.att) : (isFinish?1:0);
                  }
                  // console.log('::getDaysToTestValid daysToTestValid,daysToTestEnded=', this.daysToTestValid, this.daysToTestEnded);
                });  
              }else{
                this.daysToTestEnded = null;
                // console.log('::getDaysToTestValid testing not found');
              }
            });
          },
          checkTestIsPublished(){
            let $this = this;
            // console.log('::checkTestIsPublished ',this.test.ACTIVE, this.parent.isModer);
            if(this.test.ACTIVE !== 'Y'){
              $this.parent.notify_warning = true;
              $this.parent.notify_title = this.message[this.lang].all_req_title;
              $this.parent.notify_text = this.message[this.lang].not_published_yet; //not_published_yet:"Тест в настоящее время не опубликован",
              $this.parent.notify = true;
              if(!this.parent.isModer){
                this.$emit('goback', 'studentProfile');
                return false;
              }
            }
            return true;
          },

          checkUserTest(){
            let $this = this;
            let test_id = parseInt($this.test.ID);
            let user_id = parseInt($this.parent.user_current.ID);
            // если это тест в курсе
            if ($this.nav) {
              let course_id = parseInt($this.course_id);
              $this.parent.uCoursesTestsGetActive('course', user_id, course_id, true).then(res=> {
                if (res) {
                  let userCourse = res.data;
                  // console.log(':test: userCourse=',userCourse);
                  if (userCourse.tests) {
                    let foundIndex = userCourse.tests.findIndex((element, index, array) => {
                      return element.test_id == test_id
                    }, $this);
                    // console.log(':2: foundIndex=',foundIndex);
                    if (foundIndex >= 0) {
                      let userTest = userCourse.tests[foundIndex];
                      if(userTest.passed){
                        //popup - тест пройден
                        if(userTest.test_passed) {
                          let test_result = userTest.test_result;
                          $this.parent.notify_warning = false;
                          $this.parent.notify_title = this.message[this.lang].test_passed.toUpperCase();
                          $this.parent.notify_text = this.message[this.lang].testAllreadyPassed;
                          $this.parent.notify_text += ' '+this.message[this.lang].days_to_passed+': '+test_result+'%';
                          $this.parent.notify = true;
                        }
                      }
                      $this.correctSavedUserTest(userTest);
                    }
                  }
                }
              }, err=>{
                console.error(err)
              });

            }else{
              // если это самостоятельный тест
              this.parent.uCoursesTestsGetActive('test', user_id, test_id, true).then(res=> {
                // console.log('::checkUserTest res=',res);
                let userTest = res.data;
                if(userTest && userTest.test_passed) {
                  let test_result = userTest.test_result;
                  $this.parent.notify_warning = false;
                  $this.parent.notify_title = this.message[this.lang].test_passed.toUpperCase();
                  $this.parent.notify_text = this.message[this.lang].testAllreadyPassed;
                  $this.parent.notify_text += ' '+this.message[this.lang].days_to_passed+': '+test_result+'%';
                  $this.parent.notify = true;
                }

                $this.correctSavedUserTest(userTest);

              }, err=>{console.log('::uCoursesTestsGetActive text not active',err)});

            }
          },
          correctSavedUserTest(userTest){
            if(userTest) {
              //Подкорректируем сохраненный тест
              let att = 0;
              this.parent.userOption('get','test_' + this.test.ID).then(savedTestLast=>{
                if (this.parent.isJSON(savedTestLast)) {
                  savedTestLast = JSON.parse(savedTestLast);
                  att = savedTestLast.att ? savedTestLast.att : 0;
                }
                let savedTest = {
                  startTime: userTest.startTime,
                  passed: userTest.test_passed,
                  isEnded: userTest.endTime,
                  att: att
                };
                this.parent.userOption('set','test_' + this.test.ID, JSON.stringify(savedTest));
                this.getDaysToTestValid();
              });
            }
          },
          saveTestToCourseOption(startTime, score, percentOfRightAnswers, isEnd, wrongAnswers){
            let $this = this;
            let status = parseInt(score) <= parseInt(percentOfRightAnswers.toFixed(0));
            let result = percentOfRightAnswers.toFixed(0) +'/'+score;

            console.log(':***:saveTestToCourseOption ',score, percentOfRightAnswers, status, result)

            return new Promise(function(resolve, reject) {
              // Только если в тест зашли из курса
              if ($this.course_id) {
                let test_id = parseInt($this.test.ID);
                let test_name = $this.test.NAME;
                let course_id = parseInt($this.course_id);
                let user_id = parseInt($this.parent.user_current.ID);
                let show_results =$this.test.PROPERTY_VALUES.show_results;
                let no_prev = $this.test.PROPERTY_VALUES.no_prev;
                let test_score =$this.test.PROPERTY_VALUES.score;

                /////// new
                let testItem=
                  {
                  test_id: parseInt(test_id),
                  startTime: $this.currentTest.startTime,
                  test_score: test_score,
                  test_name: test_name,
                  status: status?'Y':'N',
                  test_passed: status,
                  result: result,
                  test_result: result,
                  isEnd: isEnd,
                  course_id: parseInt(course_id),
                  show_results:show_results,
                  no_prev:no_prev,
                  userCourse_id: $this.userCourse_id,
                  wrongAnswers: wrongAnswers,
                  sched_id: $this.testItem.sched_id,
                  program_chedule_id: $this.testItem.program_chedule_id,
                  points:$this.currentTest.flags.points,
                  type:$this.currentTest.type
                };

                console.log(':***:saveTestToCourseOption testItem=',testItem)

                $this.parent.uCoursesTestsGetActive('course', user_id, course_id, true).then(res=>{
                  // console.log(':***:saveTestToCourseOption user Course=',res)
                  if(res){
                    let userCourse = res.data;
                    if(userCourse.tests){
                      let foundIndex = userCourse.tests.findIndex(element=>{return element.test_id == test_id });
                      // console.log(':***:saveTestToCourseOption foundIndex, test_id=',foundIndex, test_id)
                      if(foundIndex==-1){
                        userCourse.tests.push(testItem);
                      }else{
                        userCourse.tests[foundIndex] = testItem;
                      }

                    }else{
                      userCourse.tests=[];
                      userCourse.tests.push(testItem);
                    }

                    // $this.parent.uCoursesTestsSave('course', user_id, userCourse).then( res=>{
                    // });
                    console.log(':***:saveTestToCourseOption userCourse=',userCourse)
                    $this.parent.uCoursesTestsSet('course', user_id, userCourse,  res.id).then(ok => {
                      // console.log('::uCoursesTestsSet ok= ', ok);
                    });

                  }else{
                    console.error(':: userCourse not recording')
                  }

                },err=>{
                  // console.error('::saveTestToCourseOption no active curse found',err)
                });


              } else {
                //console.log(':/1:saveTestToCourseOption  нет навигации ');
                reject('');
              }
            });
          },
          modersNotify(test_id, test_name, action){
            let message = this.user_current.ID+' '+this.user_current.NAME + ' ' +this.parent.user_current.LAST_NAME + ' '
              + this.message[this.lang]['test_' + action] + ' ' + test_id + ' ' + test_name;// Уведомления

              // this.parent.isNoteSet(this.parent.moderators.map(el => {return el.id}), "self").then(checks => {
              //   if (Object.keys(checks).length > 0) {
              //     let arBatch2 = {};
              //     for (let uid of checks) {
              //       arBatch2['note_' + uid] = ['im.notify', {to: uid, message: message, type: 'SYSTEM'}];
              //     }
              //     this.parent.callBatch(arBatch2);
              //   }
              // });
            },
            /**
             * Если включены блоки, то перед стартом теста составляется список активных блоков,
             * он перемешивается если включен флаг в настройках блоков «выводить блоки в случайном порядке»
             * Далее для каждого блока из списка отбираются вопросы, если для блока стоит флаг «выводить вопросы в случайном порядке»,
             * то вопросы перемешиваются в этом подсписке для блока и подсписок добавляется к общему списку вопросов, который будет выведен
             */
          modBlocksQuestions(questions_isx){
            if(!this.test.PROPERTY_VALUES.blocks){
              console.error(':: не заданы блоки test=', this.test)
              return questions_isx;
            }
            let questions = [...questions_isx];
            // console.log(':***:modBlocksQuestions questions=',questions)
            let is_rand = this.test.PROPERTY_VALUES.flags.block_rand;
            let block_list = is_rand ? this.test.PROPERTY_VALUES.blocks.sort(this.randFunc) : this.test.PROPERTY_VALUES.blocks;
            let ret=[];
            // console.log(':***: block_list=',block_list)

            //для каждого блока отберем вопросы с этим блоком если блок активен
            for(let item_block of block_list) {
              // console.log(':***: item_block=',item_block)
              let chain_block = questions.filter(el => {
                return el.block == item_block.id && item_block.active
              });


              if (chain_block && chain_block.length > 0) {
                // console.log(':*1*: chain_block=',chain_block)
                if (item_block.rand) {
                  chain_block = chain_block.sort(this.randFunc);
                }

                let count = parseInt(item_block.count)
                // console.log(':*2*: chain_block.length>count=',chain_block.length, ' count=',count);
                if (!isNaN(count) && count>0) {
                  chain_block = chain_block.splice(0, count);
                }

                // console.log(':*3*: chain_block=',chain_block)

                ret = [...ret, ...chain_block];
                // console.log(':***:modBlocksQuestions ret=',ret)
              }
            }
            // console.log(':======:modBlocksQuestions ret=',ret)
            return ret;
          },
          startTest(){
            let $this = this;
            // let startTime = Math.floor(Date.now() / 1000); //количество секунд с начало эпохи Unix
            this.parent.getServerTimeStamp().then(startTime=> {
              this.parent.userOption('get','test_' + this.test.ID).then(savedTest=>{
              // console.log('::startTest savedTest=', savedTest);
              if (this.parent.isJSON(savedTest)) {
                savedTest = JSON.parse(savedTest);
                  savedTest.startTime = startTime; //новая попытка с новым временем старта
                  savedTest.isEnded = false;
                  savedTest.passed = false;
                  savedTest.att = savedTest.att?parseInt(savedTest.att)+1:1;
              } else {
                // console.log('::startTest: не было сохраненного теста, то юзер его еще не проходил никогда');
                this.parent.userOption('set','test_' + $this.test.ID, '');
                savedTest = {startTime: startTime, passed: false, att:1};
                // let type = $this.test.PROPERTY_VALUES.type === 'final' ? 'I':'T';
                // $this.parent.savePoints(type, $this.test.ID);
              }
              if(this.test.PROPERTY_VALUES.flags.att) {
                this.countTestAttempts = savedTest.att ? parseInt(savedTest.att) : 1;
                // console.log('::startTest countTestAttempts=', this.countTestAttempts, 'savedTest ',savedTest);
              }
              this.parent.userOption('set','test_' + this.test.ID, JSON.stringify(savedTest));
              this.testMessageResult = '';
              this.testMessage = '';
              $this.isTestRun = true;
              $this.currentTest = '';
              //console.log('startTest ',startTime);
              let tick0 = true;

              this.timerId = setInterval(() => {
                this.parent.getServerTimeStamp().then(currentTime=> {
                  // let currentTime = Math.floor(Date.now() / 1000);
                  if (!$this.currentTest) {
                    //Старт
                    let todisplay = $this.test.PROPERTY_VALUES.todisplay;
                    let no_mix = $this.test.PROPERTY_VALUES.flags.no_mix;
                    let no_mix_answ = $this.test.PROPERTY_VALUES.flags.no_mix_answ;
                    let is_blocks = $this.test.PROPERTY_VALUES.flags.block;
                    // $this.isTestRun = true;
                    let rand_questions = this.test.PROPERTY_VALUES.questions;
                    if(is_blocks){
                      rand_questions = this.modBlocksQuestions(rand_questions);
                      this.test.PROPERTY_VALUES.todisplay = rand_questions.length;
                    }else {
                      /**
                       * Если блоки не включены в настройках теста, то перемешивание делается по флажку в настройках теста
                       */
                      rand_questions = no_mix ? $this.test.PROPERTY_VALUES.questions : $this.test.PROPERTY_VALUES.questions.sort($this.randFunc);
                      if (!isNaN(todisplay) && parseInt(todisplay) > 0) {
                        rand_questions.length = todisplay;
                        // console.log('::rand_questions= ',rand_questions)
                      }
                    }
                    let wAnswers = $this.initAnswers(rand_questions);
                    let answers = wAnswers[0];
                    let answers2 = wAnswers[1];

                  // console.log(':1:startTest rand_questions ######## ',rand_questions);
                  // console.log(':2:startTest answers ######## ',answers,answers2);
                  // for(let u in $this.test.PROPERTY_VALUES.answers){
                  //     console.log('--',u,'-------');
                  //     for(let v in $this.test.PROPERTY_VALUES.answers[u]){
                  //         console.log('--',v,'--', $this.test.PROPERTY_VALUES.answers[u][v].text);
                  //     }
                  // }
                  //
                  $this.currentTest = {
                    currentQuest: 0,
                    questions: rand_questions,
                    startTime: startTime,
                    currentTime: currentTime,
                    test_id: parseInt($this.test.ID),
                    test_name: $this.test.NAME,
                    score: $this.test.PROPERTY_VALUES.score,
                    todisplay: todisplay,
                    answers: answers,
                    answers2: answers2,
                    action: '',
                    points: '',
                    time_left: parseInt($this.test.PROPERTY_VALUES.time) * 60,
                    type: $this.test.PROPERTY_VALUES.type,
                    responsible: $this.test.responsible,
                    isWeight: $this.test.isWeight,
                    show_results: $this.test.PROPERTY_VALUES.show_results,
                    no_prev: $this.test.PROPERTY_VALUES.no_prev,
                    userCourse_id: $this.userCourse_id,
                    flags:$this.test.PROPERTY_VALUES.flags,
                    sched_id: $this.testItem.sched_id,
                    program_chedule_id: $this.testItem.program_chedule_id,
                    blocks:$this.test.PROPERTY_VALUES.blocks,
                    isEnd:false
                  };
                  if($this.parent.debug_on){
                    $this.currentTest.show_results = 3;
                  }
                  // console.log(':: startTest currentTest=', $this.currentTest);
                  $this.saveTestToAppOption('', '', true)
                  $this.modersNotify($this.test.ID, $this.test.NAME, 'start');
                } else {
                  //Очередной тик
                  // $this.currentTest = JSON.parse($this.currentTest);
                  $this.currentTest.currentTime = currentTime;
                  //$this.currentTest.answers = $this.test.PROPERTY_VALUES.answers;
                  startTime = $this.currentTest.startTime;
                  if (tick0) {
                    tick0 = false;
                    // BX24.scrollParentWindow(-1, function () {
                    // });
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth' 
                    });
                  }
                }

                  //Проверка на превышение времени теста
                  // let deltaTime = Math.floor((currentTime - $this.currentTest.startTime)/60);
                  let deltaTime = currentTime - $this.currentTest.startTime;
                  $this.currentTest.time_left = parseInt($this.test.PROPERTY_VALUES.time) * 60 - deltaTime;
                  $this.currentTest.time_left > 0 ? $this.currentTest.time_left : 0;

                  let ct = dayjs(1000*currentTime).format('YYYY-MM-DD HH:mm:ss');
                  let st = dayjs(1000*$this.currentTest.startTime).format('YYYY-MM-DD HH:mm:ss');

                  // console.log(':***:',ct,st, deltaTime, $this.currentTest.time_left, $this.test.PROPERTY_VALUES.time);
                  if ($this.currentTest.time_left <= 0) {
                    // console.log('::startTest превышено время теста ',parseInt($this.test.PROPERTY_VALUES.time));
                    $this.saveCurrentTest('time-is-up', $this.currentTest.currentQuest, 0);
                    $this.testMessage = $this.message[$this.lang].time_is_up;
                    clearInterval(this.timerId);
                  }
                });
                // /settimeout
              }, 2*1000);
            });
            });
          },
          stopTest(){

            let $this=this;
            $this.parent.title_confirm = this.message[this.lang].title_stop_test;
            $this.parent.message_confirm = this.message[this.lang].message_stop_test;
            $this.parent.do_confirm = ()=>{
              $this.saveCurrentTest('stop', $this.currentTest.currentQuest, 0);
              $this.parent.getUserScheduledCourses();
              $this.parent.confirm = false;
            };
            // eslint-disable-next-line vue/no-mutating-props
            this.parent.confirm = true;
          },
          saveCurrentTest(action, currentQuest, step){
            //let saveCurrentTest = JSON.stringify(this.currentTest);
            let points=0;
            this.showAlertForQuestion = false;

            let countOpens=0;
            let wrongAnswers=[];
            // console.log(':!!!: this.currentTest.answers=',this.currentTest.answers)
            for(let i in this.currentTest.answers) {
              let answ_id = i;
              let quest = this.currentTest.questions.find((element, index, array) => {
                if (element.value == answ_id) return true; else return false
              }, this);

              let et_answers = [];
              let weight = 1; //Если веса не заданы, то вес каждого вопроса == 1
              if (quest) {
                weight = quest.weight ? parseInt(quest.weight) : 1; //Если у вопроса задан вес, то берем его, иначе вес вопроса == 1
                //Проставим такой-же вес в эталонный ответ
                for (let k in this.test.PROPERTY_VALUES.answers) {
                  // console.log(':et: ',k,  quest.value);
                  if (k == quest.value) {
                    for (let s in this.test.PROPERTY_VALUES.answers[k]) {
                      et_answers.push(this.test.PROPERTY_VALUES.answers[k][s]);
                    }
                    break;
                  }
                }

                let allRight=false;
                let false_current = null;
                let iKey = 'zzz';// String(parseInt(quest.value) - 1);
                if(currentQuest){
                  iKey = parseInt(this.currentTest.questions[parseInt(currentQuest)].value);
                  // console.log('::iKey=',iKey, this.currentTest.questions[parseInt(currentQuest)]);
                }
                // console.log(':: quest.type=',quest.type);
                if (quest.type && quest.type === 'seq') {
                  // console.log('=== seq ===');// Вопрос-Последовательность
                  let isRight = true;
                  let arAnswers = [];
                  for (let j in this.currentTest.answers[i]) {
                    let cur_answer = this.currentTest.answers[i][j];
                    let et_answer = et_answers[j];
                    // console.log(cur_answer.text,' -?-> ', et_answer.text);
                    if (cur_answer.text != et_answer.text) {
                      isRight = false;
                      // break;
                    }
                    let answ = this.currentTest.answers[i][j];
                    arAnswers.push({
                      right: answ.right,
                      text: answ.text,
                      value: cur_answer.value,
                      checked: answ.checked
                    });
                  }
                  if (Object.keys(arAnswers).length > 0) {
                    wrongAnswers.push({
                      q: i,
                      right: isRight,
                      qt: this.parent.isBase64Encoded(quest.text)?quest.text:this.parent.utf8_to_b64(this.parent.replaceQuotesBySpace(quest.text)),
                      a: arAnswers,
                      et: et_answers,
                      type: quest.type
                    });
                  }
                  points += isRight ? weight : 0;
                  allRight = isRight;

                }
                else if (quest.type && quest.type === 'eqw') {
                  // console.log('=== eqw ==='); //Вопрос-соответствие
                  let isRight = true;
                  let arAnswers = [];
                  for (let j in this.currentTest.answers[i]) {
                    let cur_answer = this.currentTest.answers[i][j];
                    let cur_answer2 = this.currentTest.answers2[i][j];
                    // console.log(cur_answer.values, '=?=', cur_answer2.value, cur_answer.values.includes(cur_answer2.value));
                    if (cur_answer.values){
                      if (!cur_answer.values.includes(cur_answer2.value)) {
                        isRight = false;
                      }
                    }else if (cur_answer.value != cur_answer2.value) {
                        isRight = false;
                    }
                    let answ = this.currentTest.answers[i][j];
                    arAnswers.push({
                      right: answ.right,
                      text: answ.text,
                      value: cur_answer.value,
                      checked: answ.checked
                    });
                  }
                  if (Object.keys(arAnswers).length > 0) {
                    wrongAnswers.push({
                      q: i,
                      right: isRight,
                      qt: this.parent.isBase64Encoded(quest.text)?quest.text:this.parent.utf8_to_b64(this.parent.replaceQuotesBySpace(quest.text)),
                      a: arAnswers,
                      type: 'eqw',
                      et: this.currentTest.answers2[i],
                      bl: quest.block
                    });
                  }
                  points += isRight ? weight : 0;
                  allRight = isRight;
                  // console.log('=== eqw === wrongAnswers=', wrongAnswers);
                  // if (currentQuest && parseInt(quest.value) == parseInt(iKey) && !isRight) {
                  //   console.error(':iKey=' + iKey + ': currentQuest=', currentQuest, this.currentTest.answers[i]);
                  //   false_current = iKey;
                  // }
                }
                else if (quest.type && quest.type === 'sub') {
                  // console.log(':sub:currentTest ',this.currentTest);
                  // console.log(':sub: quest: action === stop', quest);
                  let isRight = true;
                  let arAnswers = [];
                  for (let j in this.currentTest.answers[i]) {
                    let cur_answer = this.currentTest.answers[i][j];
                    let cur_answer2 = this.currentTest.answers2[i] && this.currentTest.answers2[i][j]?''+this.currentTest.answers2[i][j]:'';
                    cur_answer2 = cur_answer2.trim();

                    // console.log(cur_answer.value, cur_answer.text,' =?= ', j, cur_answer2, cur_answer.text == cur_answer2);

                    if ((''+cur_answer.text).trim() != cur_answer2) {
                      isRight = false;
                    }
                    let answ = this.currentTest.answers[i][j];
                    arAnswers.push({
                      right: (''+cur_answer.text).trim().toLowerCase() == cur_answer2.toLowerCase(), //answ.right,
                      text: answ.text,
                      value: cur_answer.value,
                      checked: answ.checked
                    });
                  }
                  if (Object.keys(arAnswers).length > 0) {
                    wrongAnswers.push({
                      q: i,
                      right: isRight,
                      qt: this.parent.isBase64Encoded(quest.text)?quest.text:this.parent.utf8_to_b64(this.parent.replaceQuotesBySpace(quest.text)),
                      a: arAnswers,
                      type: 'sub',
                      et: this.currentTest.answers2[i],
                      bl: quest.block
                    });
                  }
                  points += isRight ? weight : 0;
                  allRight = isRight;

                }
                else if (quest.type && quest.type === 'opn') {
                  // let opnTextarea = this.currentTest.answers[this.currentTest.questions[this.currentTest.currentQuest].value];
                  allRight = true; //String(opnTextarea).trim().length > 0;
                  countOpens++;
                  let opn_answer = this.currentTest.answers[i];
                  // if(opn_answer){
                  //   opn_answer = this.parent.utf8_to_b64(this.parent.replaceQuotesBySpace(opn_answer));
                  // }
                  wrongAnswers.push({
                    q: i,
                    qt: this.parent.isBase64Encoded(quest.text)?quest.text:this.parent.utf8_to_b64(this.parent.replaceQuotesBySpace(quest.text)),
                    type: 'opn',
                    a: [{text: this.parent.utf8_to_b64(this.parent.replaceQuotesBySpace(opn_answer)), opn: 'Y', checked: false, right: false}],
                    bl: quest.block
                  });
                  if (action === 'stop' || action === 'time-is-up') {
                    // console.log('::currentTest ',this.currentTest);
                    // console.log('::quest: action === stop', quest, opn_answer);

                    //если ответ не пустой сохраним его для проверки
                    if(opn_answer && opn_answer.trim()) {
                      let stud_fio =` (${this.user_current.NAME} ${this.user_current.LAST_NAME})`;
                      opn_answer += stud_fio;
                      let hash = this.parent.myEx(quest.text);
                      // console.log(':###: save test hash=',hash, this.opnFiles, quest.text);
                      let files = this.opnFiles[`f_${hash}`] ? this.opnFiles[`f_${hash}`] : '';
                      //Добавим назначение вопроса ответственному
                      //
                      let resp_id = quest.responsible.id; //-test_id-quest_id
                      let uniq_test_id = this.currentTest.test_id + '_' + this.currentTest.startTime;
                      this.parent.opensSet(resp_id, this.user_current.ID, uniq_test_id, quest.value, 
                          this.parent.removeHtmlTags(this.parent.showBase64Text(quest.text)), 
                          opn_answer, '', files, this.testItem.program_chedule_id).then((opnID) => {
                            console.log('::opensSet opnID=',opnID)
                      //Баллы за отправку открытого вопроса
                      this.parent.savePoints('O', opnID, null, resp_id);
                        // console.error(`:${this.currentTest.test_id}-${quest.value}: opensSet ID=${opnID}`)
                        //Уведомление
                        // if(!opnID) {
                        let message = this.message[this.lang].responsible_assigned + ' "' + this.parent.removeHtmlTags(this.parent.showBase64Text(quest.text)) +
                          '"' + stud_fio +
                          this.message[this.lang].responsible_assigned2;
                        // Уведомления
                        // this.parent.isNoteSet([resp_id], "open").then(checks => {
                        //   if (Object.keys(checks).length > 0) {
                        //     let arBatch2 = {};
                        //     for (let uid of checks) {
                        //       arBatch2['note_' + uid] = ['im.notify', {to: uid, message: message, type: 'SYSTEM'}];
                        //     }
                        //     this.parent.callBatch(arBatch2);
                        //   }
                        // });
                        // Уведомление в ЛК
                        this.parent.notifyLK(resp_id, message);
                        // }
                      });
                    }
                  }
                }
                else {
                  // console.log('=== qa ==='); Вопрос-выбор
                  if (this.currentTest.answers[i] && Object.keys(this.currentTest.answers[i]).length > 0) {
                    let isRight = true;
                    let arAnswers = [];


                    for (const j in this.currentTest.answers[i]) {

                      if (this.currentTest.answers[i][j].right && !this.currentTest.answers[i][j].checked ||
                        !this.currentTest.answers[i][j].right && this.currentTest.answers[i][j].checked) {
                        isRight = false; //не отмечен правильный ответ
                      }

                      let answ = this.currentTest.answers[i][j];
                      arAnswers.push({
                        right: answ.right,
                        text: answ.text,
                        checked: answ.checked,
                        value: answ.value
                      });
                    }

                    if (Object.keys(arAnswers).length > 0) {
                      wrongAnswers.push({q: i, right: isRight,
                        qt:this.parent.isBase64Encoded(quest.text)?quest.text: this.parent.utf8_to_b64(this.parent.replaceQuotesBySpace(quest.text)),
                        a: arAnswers, type: 'qa', bl:quest.block});
                    }
                    points += isRight ? weight : 0;
                    allRight = isRight;
                  }
                }
                //


                // console.log(':2: showAlertQuestion currentQuest,iKey=',currentQuest, iKey, quest.value, quest)
                if (currentQuest && parseInt(quest.value) == parseInt(iKey)) {
                  if(this.allRight === 'noset'){
                    this.allRight = null;
                  }else {
                    this.allRight = allRight;
                  }
                  // console.log(':: this.allRight=',this.allRight);
                  if (allRight) {
                    // console.error('::след.вопрос ', parseInt(currentQuest) + step);
                    this.currentTest.currentQuest = parseInt(currentQuest) + step;
                  }else{
                    // console.error('::false_current= ', false_current);
                    this.showAlertForQuestion = true;
                    if(quest.type !== 'opn' && this.currentTest.no_prev==='Y'  && this.currentTest.show_results == 3){
                     //здесь показать в попапе, что ответ неверный и по кнопке перейти к след вопросу
                      // console.log(':1: showAlertQuestion quest.type=',quest.type)
                      this.showAlertQuestion = true;
                    }else {
                      // console.log(':2: showAlertQuestion currentQuest,step=',currentQuest, step)
                      this.currentTest.currentQuest = parseInt(currentQuest) + step;
                    }
                  }

                }else if(parseInt(quest.value) == parseInt(iKey) &&
                            quest.type !== 'opn' && !this.allRight && action === 'stop' &&
                                this.currentTest.no_prev==='Y' && this.currentTest.show_results == 3){
                  // console.log(':2: showAlertQuestion ',quest.type)
                  this.showAlertQuestion = true;
                }

              }
              // else{
              //   console.error(':: quest not found answ_id=',answ_id);
              //   console.log(':: this.currentTest.questions=', this.currentTest.questions)
              // }
            }
            if(step && this.currentTest.currentQuest != parseInt(currentQuest) + step){
              console.error(':: !!! kostyl ', this.currentTest.currentQuest, step)
              this.currentTest.currentQuest = parseInt(currentQuest) + step;
            }

            this.currentTest.wrongAnswers=Object.keys(wrongAnswers).length>0 ? wrongAnswers : null;
            this.currentTest.action=action;
            this.currentTest.points=points; //Количество или сумма весов правильных ответов
            let m100 = this.currentTest.isWeight ? 100.0 : this.questToDisplay;
            //если есть веса, то считать будем в % и делить на 100
            //если веса не заданы, то делим на количество выводимых вопросов
            let percentOfRightAnswers = Math.round(100*points/m100); // процент правильных ответов
            let percentOfRightAnswers_vs_Opens = Math.round(100*(points-countOpens)/m100);
            // percentOfRightAnswers = percentOfRightAnswers < 0 ? 0 : (percentOfRightAnswers > 100 ? 100 : percentOfRightAnswers);
            this.currentTest.pcRight = percentOfRightAnswers; //Для отображения на экране только
            let passed = this.currentTest.score <= percentOfRightAnswers; //тест пройден?
            let passed_vs_Opens = this.currentTest.score <= percentOfRightAnswers_vs_Opens;
            this.currentTest.isPassed = passed;
            let is = passed ? this.message[this.lang].passed : this.message[this.lang].nopassed;
            if(passed){
                // console.log('::passed for_course', this.testItem.for_course, this.test.PROPERTY_VALUES.flags)
                let for_course=this.testItem.for_course;
                let isPointsSave = false;
                const test_type = this.test.PROPERTY_VALUES.type;
                let type = test_type === 'total' || test_type === 'final' ? 'I':'T';
                  
                let value = this.test.PROPERTY_VALUES.flags.points?parseInt(this.test.PROPERTY_VALUES.flags.points):0;
                if(!value){
                  console.log('::Взять баллы из настроек type=', type);
                  value = this.parent.getPointsForModType(type);
                }
                if(value && (!for_course || for_course && (!for_course.active || for_course.active && for_course.dop === 'Y'))){
                  //в курсе заданы условия что за уроки нужно начислять дополнительно
                  this.parent.savePoints(type, this.testItem.ID, value);
                }
                if(value && !isPointsSave){
                  let for_prog=this.testItem.for_prog;
                  if(!for_prog || for_prog && (!for_prog.active || for_prog.active && for_prog.dop === 'Y')){
                    this.parent.savePoints(type, this.testItem.ID, value);
                  }
                }

              }
              
            if(this.isTestRun && action && (action === 'time-is-up' || action === 'stop')){
              let stage = passed || passed_vs_Opens ? 'Y':'N'; //this.currentTest.score > points + countOpens ? 'N' : 'Y';
              this.setStage(stage, passed);
              // if(passed){
              //   //console.log('this.currentTest=',this.currentTest);
              //   let type = this.currentTest.type === 'total' ? 'I':'T';
              //   this.parent.savePoints(type, this.test.ID);
              // }
              

              this.testMessageResult=this.message[this.lang].test_result+': '+parseInt(points);
              if(this.currentTest.isWeight){
                this.testMessageResult += '% '+this.message[this.lang].right_quest
              }else{
                this.testMessageResult += '/'+this.questToDisplay;
              }


              if(countOpens){
                this.testMessageResult += '<br>'+this.message[this.lang].test_result_opn;
                this.countOpens = countOpens;
              }else{
                this.countOpens = 0;
                this.testMessageResult += " : "+is;
              }

              this.parent.userOption('get','test_'+this.test.ID).then(savedTestLast=>{
                let att=1;
                if(this.parent.isJSON(savedTestLast)){
                  savedTestLast = JSON.parse(savedTestLast);
                  att = savedTestLast.att?savedTestLast.att:1;
                }  
                let savedTest = {startTime:this.currentTest.startTime, passed:passed, isEnded:true, att:att};
                this.parent.userOption('set','test_'+this.test.ID, JSON.stringify(savedTest));
              });
              
              
              this.saveTestToAppOption(countOpens, 'N');

              this.getDaysToTestValid(true);
              this.isTestRun = false;
              // console.log(':: action=', action, ' this.isTestRun=',this.isTestRun);
              if(this.timerId){
                // console.log(':: clearInterval', this.timerId);
                clearInterval(this.timerId);
              }

              this.modersNotify(this.currentTest.test_id, this.currentTest.test_name, 'end');

              this.parent.showAOS({props_aos:this.test.PROPERTY_VALUES.flags.aos, id:this.test.ID, name:this.test.NAME, type:'test'});

              if(this.currentTest.sched_id) {
                this.parent.checkTaskScheduled(this.currentTest.sched_id, this.currentTest.test_id);
              }

              // if(this.testItem.program_chedule_id) {
                let emit_data = {
                  program_chedule_id:this.testItem.program_chedule_id,
                  module_id:this.currentTest.test_id,
                  passed:passed,
                  type:'test',
                  type2:this.test.PROPERTY_VALUES.type,
                  pt:this.test.PROPERTY_VALUES.flags.points
                };
                console.log(':+++: complete test emit courseReturn',emit_data);
                if(this.currentTest.userCourse_id){
                  this.$emit('moduleReturn', JSON.stringify(emit_data));
                }else{
                  this.$emit('courseReturn', JSON.stringify(emit_data));
                }
              // }

            }
            else{
              this.parent.userOption('get','test_'+this.test.ID).then(savedTestLast=>{
                let att=1;
                if(this.parent.isJSON(savedTestLast)){
                  savedTestLast = JSON.parse(savedTestLast);
                  att = savedTestLast.att?savedTestLast.att:1;
                }
                let savedTest={startTime:this.currentTest.startTime, passed:passed, isEnded:false, att:att};
                this.parent.userOption('set','test_'+this.test.ID, JSON.stringify(savedTest));                
              });
              
              this.saveTestToAppOption(countOpens);
            }

          },

          /**
           * Сохранить тест в попытку юзера
           * @param countOpens - количество открыртых вопросов данных для ответа
           * @param active -  Y - тест в процессе, N - завершен
           * @param isStart - старт теста
           */
          saveTestToAppOption(countOpens, active, isStart){
            active = active ? active : 'Y';
            countOpens = countOpens?parseInt(countOpens):0;
            // console.log('::saveTestToAppOption currentTest= ', this.currentTest);
            let $this = this;
            let user_id = this.parent.user_current.ID;

            let questToDisplay = parseInt(this.currentTest.todisplay) ? parseInt(this.currentTest.todisplay) : Object.keys(this.currentTest.questions).length;
            let m100 = this.currentTest.isWeight ? 100.0 : questToDisplay;
            let percentOfRightAnswers = Math.round(100*(this.currentTest.points)/m100);
            let passed = this.currentTest.score <= percentOfRightAnswers;
            // то что пишется в результат теста
            let test_result = percentOfRightAnswers.toFixed(0)+'/'+this.currentTest.score;

            this.parent.getServerTimeStamp().then(curTime=> {
              let new_test = {
                test_id: parseInt(this.currentTest.test_id),
                startTime: this.currentTest.startTime,
                endTime: curTime,
                test_name: this.currentTest.test_name,
                test_passed: passed,
                isEnd:false,
                test_result: test_result,
                test_score: this.currentTest.score,
                wrongAnswers: this.currentTest.wrongAnswers,
                course_id: parseInt(this.course_id),
                show_results: this.currentTest.show_results,
                no_prev: this.currentTest.no_prev,
                userCourse_id: this.userCourse_id,
                flags:this.currentTest.flags,
                sched_id: this.testItem.sched_id,
                program_chedule_id: this.testItem.program_chedule_id,
                blocks: this.currentTest.blocks
              };

              if(isStart){
                // Если это старт, то это новая попытка
                // console.log(':***:uCoursesTestsSet new_test=',new_test)
                this.parent.uCoursesTestsSet('test', user_id, new_test).then(res => {
                  // console.log(':+++:uCoursesTestsSet new test attempt ', res);
                  this.currentTest.userTest_id = res.result;
                });
              }else {
                //Если это промежуточное сохранение результатов, то ришем в текущую попытку
                this.parent.uCoursesTestsSet('test', user_id, new_test, this.currentTest.userTest_id).then(res => {
                  // console.log(':===:uCoursesTestsSave upd ',res);
                });
              }

              if ($this.course_id) {
                this.saveTestToCourseOption('', $this.currentTest.score, percentOfRightAnswers, active==='N', $this.currentTest.wrongAnswers).then(
                  ok => {
                    // $this.currentTest = null;
                  },
                  er => {
                    // $this.currentTest = null;
                  });
              }
            });

          },

          initAnswers(questions){
            //Клонируем вопросы оставив в test исходные для сравнения в конце
            let test_answers = {};
            let test_answers2={};
            let no_mix_answ = this.test.PROPERTY_VALUES.flags.no_mix_answ;
            if(this.test.PROPERTY_VALUES.answers && Object.keys(this.test.PROPERTY_VALUES.answers).length>0) {
              for (let k in this.test.PROPERTY_VALUES.answers) {
                test_answers[k] = {};
                for (let l in this.test.PROPERTY_VALUES.answers[k]) {
                  test_answers[k][l] = this.test.PROPERTY_VALUES.answers[k][l];
                  test_answers[k][l].text = this.parent.replaceQuotesBySpace(test_answers[k][l].text, '_');
                  if(test_answers[k][l].text2){
                    test_answers[k][l].text2 = this.parent.replaceQuotesBySpace(test_answers[k][l].text2, '_');
                  }
                  // console.log(':: initAnswers k=', k, ' l=', l, ' test_answers[k][l]=', test_answers[k][l]);
                }
              }
              // Дальше все на клоне
              for (let i in test_answers) {
                let answ_id = i;

                let quest = questions.find((element, index, array) => {
                  if (element.value == answ_id) return true; else return false
                }, this);
                if (quest && quest.type && quest.type === 'opn') {
                  test_answers[answ_id] = "";
                } else if (quest && quest.type && quest.type === 'seq') {
                  test_answers[answ_id] = this.sortAssocArray(test_answers[answ_id]);
                } else if (quest && quest.type && quest.type === 'eqw') {
                  //для вопросов типа соответствие продублируем ответы
                  // console.log('::initAnswers quest.type=',quest.type, ' answers=',test_answers[answ_id])
                  test_answers2[answ_id] = {};
                  for (let nn in test_answers[answ_id]) {
                    test_answers2[answ_id][nn] = {
                      value: test_answers[answ_id][nn].value,
                      text2: test_answers[answ_id][nn].text2,
                      file2: test_answers[answ_id][nn].file2
                    };
                  }
                  test_answers[answ_id] = this.sortAssocArray(test_answers[answ_id]);
                  test_answers2[answ_id] = this.sortAssocArray(test_answers2[answ_id]);
                } else {
                  //Для типа вопроса вопрос-ответ
                  for (let j in test_answers[answ_id]) {
                    test_answers[i][j].checked = false;
                    test_answers[i][j].data = '';
                    if (test_answers[i][j].file) {
                      // this.loadFile(test_answers[i][j]);
                      this.loadFile(i, j);
                    }
                  }
                  if(!no_mix_answ) {
                    // console.log(':: перемешаем ответы ',answ_id)
                    test_answers[answ_id] = this.sortAssocArray(test_answers[answ_id]);
                  }
                }
                // if(todisplay && i>todisplay){
                //   break;
                // }
                // console.log(':: i, todisplay=',i,todisplay)
              }
            }else{
              console.error(':: initAnswers no answers test=',this.test)
            }

            return [test_answers, test_answers2];
          },
          // Сортировка ассоциативного массива
          sortAssocArray(assArr){
              let sortable = [];
              for (var nn in assArr) {
                  sortable.push([nn, assArr[nn]]);
              }

              let sorted = sortable.sort((a, b)=>{
                  let ret=0.5 - Math.random();
                  return ret;
              });

              let objSorted = [];
              sorted.forEach(function(item){
                  objSorted.push(item[1]);
              });
              return objSorted;
          },
          randFunc(a, b) {
              return 0.5 - Math.random();
          },
          getTestById(id){
                //console.log(':: getTestById ', id);
                let $this = this;
                this.isLoading=true;
                let params = {
                  'ENTITY': 'uni_tests',
                  'SORT': {},
                  'FILTER': {
                    'ID': parseInt(id)
                  }
                };
                return new Promise(function(resolve, reject) {
                    // BX24.callMethod('entity.item.get', {
                    //     'ENTITY': 'uni_tests',
                    //     'SORT': {},
                    //     'FILTER': {
                    //         'ID': parseInt(id)
                    //     }
                    //
                    // }, function (res) {
                    //     //console.log(':: getTestById res=',res);
                    //     $this.isLoading = false;
                    //     if (res.answer.error) {
                    //         // console.error(res.answer.error_description);
                    //         // $this.msg = res.answer.error_description;
                    //         reject(res.answer.error_description);
                    //     } else {
                  let url =  $this.parent.bx24Init({ AJAX_ACTION: 'entityItemGet', params:params });//.then(params=> {
                  $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
                    $this.isLoading = false;
                  if(!res.result || Object.keys(res.result).length === 0){
                      ////console.log(':1:getTestById test not found id=',id);
                      reject('test not found id='+id);
                  }
                  else if (res.result[0]) {
                      var item = res.result[0];
                      // //console.log('::test = ', item);
                      resolve(item);
                  }else{
                      ////console.log(':2:getTestById test not found id=',id);
                      reject('test not found id='+id);
                  }
                        // }

                    });
                });
            },
          /**
           * // Добавляем стили для анимации
           const style = document.createElement('style');
           style.innerHTML = `@keyframes confetti-fall {
              0% { transform: translateY(0); }
              100% { transform: translateY(100vh); }
              }`;

           document.head.appendChild(style);

           // Вызываем функцию для создания конфетти
           createConfetti();
           */
          createConfetti() {
            const colors = ['#ff0000', '#00ff00', '#0000ff', '#ffff00', '#ff00ff', '#00ffff']; // Массив цветов конфетти
            const confettiCount = 100; // Количество конфетти

            for (let i = 0; i < confettiCount; i++) {
              const confetti = document.createElement('div');
              confetti.style.width = '10px';
              confetti.style.height = '10px';
              confetti.style.borderRadius = '50%';
              confetti.style.position = 'absolute';
              confetti.style.top = '-10px';
              confetti.style.left = Math.random() * 100 + '%';
              confetti.style.backgroundColor = colors[Math.floor(Math.random() * colors.length)];
              confetti.style.animation = 'confetti-fall 2s ease-in-out forwards';

              document.body.appendChild(confetti);
            }
          }
        },
        components: {
          draggable: VueDraggableNext,
          Comments,
          LoadImages
        }
    }
</script>

<style scoped>
    a.disabled {
        pointer-events: none;
        color:gray;
    }
    .lesson{
        /*background-color: lightgray;*/
        margin: 1px;
        padding: 2px;
        font-size: smaller;
        /*border-radius: 0.3rem;*/
    }
    .test{
        /*background-color: darkgray;*/
        margin: 1px;
        padding: 2px;
        font-size: smaller;
        /*border-radius: 0.3rem;*/
    }
    .nav-link{
        display: block;
        padding: 0.2rem;
        /*border-radius: 0.3rem;*/
        /*border-bottom: darkgray dotted 1px;*/
    }
    .for_desktop{
      padding-left: 15px;
      padding-bottom: 100px;
      padding-right: 10px;
    }
    .for_mobile{
      padding: 0px;
      margin: 0px;
    }

</style>
